import * as React from 'react';
import { View, Text, ImageBackground,TouchableOpacity, Dimensions, FlatList, Linking} from 'react-native';
//import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router';
import HomePage from './HomePage';
import selectTeam from './selectTeam';
import Privacy from './privacy';
//import Developer from './developer';
import { BrowserRouter as Router, Route, Link, HashRouter, withRouter , Switch} from "react-router-dom";
import { FaHome, FaUsers} from 'react-icons/fa';
import { MdLooks5 } from 'react-icons/md';
import Score from './Score';
import * as actions from './actions';
import { connect } from 'react-redux';
import { FaCog} from 'react-icons/fa';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import SlidingPane from 'react-sliding-pane';
import Modal from 'react-modal';
import { FaLock} from 'react-icons/fa';
import { IoMdText} from 'react-icons/io';
import Developer from './Developer'
//import AdSense from 'react-adsense';
//import AccountIcon from 'material-ui-community-icons/icons/account';


class App extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
            selectedView:'teams', 
            isPaneOpen: false,
            isPaneOpenLeft: false 
        } 
         
    }
    
    

    componentDidMount() {        
        this.onRouteChanged();  
        //Modal.setAppElement(this.el);  
              
    }
    
    componentDidUpdate(prevProps) {
        console.log(this.props.location)
        console.log(prevProps.location)
        
       if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged();
        }
      }
    
      onRouteChanged() {
        var PageURL = window.location.href.split("/")
       
       
        if (PageURL.includes("teams")){
            this.props.updateViewingPage('teams')
        }
        else if (PageURL.includes("score")){
            this.props.updateViewingPage('score')
        }
        else {
            this.props.updateViewingPage('home')
        }
        console.log("ROUTE CHANGED");
      }

    renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "black",                    
                }}
            />
        );
    };

    _renderItem = ({ item, index }) => (
      
       
            <TouchableOpacity style={{marginBottom:20, marginTop:20,flexDirection:'row', justifyContent: 'center',alignContent:'center'}}>
             {index===0 ? <FaLock size={20} color='black'/> : <IoMdText size={25} color='black'/> }
            <Text onPress={() => Linking.openURL(item.link) }
                style={{ fontWeight:500, textAlign: 'center', fontSize: 15, color: 'black', flex:0.7 }}>{item.title}</Text>
            
            </TouchableOpacity>
          
    );

    render () {
        const list = [
            {title: 'Privacy', icon: 'FaLock', link:'https://allamericanhighschoolsports.com/privacy',},
            {title: 'FeedBack', icon: 'FaLock', link:'mailto:AllAmericanHighSchoolSports@gmail.com?subject=App Support',},
        ] 
        return (      
            <div ref={ref => this.el = ref}>
                { isMobile 
                  ? <View style={{backgroundColor:  'black' , height:50,  width: '100%', margin: 0,justifyContent:'center', top:0}}>
                        <View style={{flexDirection:'row', paddingRight:20}}>
                                     <View style={{flex:1}}>
                                        <Text style={{color: '#AD3E3A', fontWeight:'bold', fontSize:23, fontFamily:  'Helvetica', paddingLeft:15,paddingRight:5 }}>AllAmerican</Text>                                
                                        <Text style={{color: 'white',  fontSize:13, fontFamily:  'Helvetica', paddingLeft:15,paddingRight:5 }}>Houston High School</Text> 
                                    </View>
                                 
                                     <FaCog style={{paddingLeft:20, paddingTop:5}} size={25} color='white' onClick={() => {this.setState({ isPaneOpen: true })}} />                              
                                     <SlidingPane
                                        className='some-custom-class'
                                        overlayClassName='some-custom-overlay-class'
                                        isOpen={ this.state.isPaneOpen }
                                        title='Setting'                                        
                                        //subtitle='Optional subtitle.'
                                        onRequestClose={ () => {
                                            // triggered on "<" on left top click or on outside click
                                            this.setState({ isPaneOpen: false });
                                        } }>
                                    <FlatList                                 
                                            data={list}
                                            renderItem={this._renderItem }
                                            ItemSeparatorComponent={this.renderSeparator}     
                                            
                                        />
                                    </SlidingPane>
                        </View>  
                    </View>
                    :
                    <View style={{backgroundColor:  'black' , height:50,  width: '100%', margin: 0,justifyContent:'center', top:0}}>
                    </View>
                }
                
                <Switch>
                    <Route path="/score/" component={Score}/>  
                    <Route path="/teams" component={selectTeam} />  
                    <Route path="/privacy" component={Privacy}/>  
                    <Route path="/developer" component={Developer}/> 
                  
                    <Route path="/" component={HomePage}/> 
                                      
                    
                </Switch>  
               
                {                
                    isMobile ? 
                    
                       
                    <View style={{backgroundColor:  'black' , height:50, position: 'fixed', width: '100%', margin: 0,bottom: 0}}>
                        <View style={{flexDirection:'row', flex:1}}>
                            <View style={{flex:1, }}>
                                <Link to="/score/" style={{ textDecoration: 'none' }}>
                                    <TouchableOpacity style={{flex:1, alignItems:'center'}} 
                                    //onPress = {() => {  this.props.updateViewingPage('score')} } 
                                        >
                                        <MdLooks5 size={30} color={this.props.viewingPage==='score'?'#BF2F1A':'white'}/>
                                        <Text style = {{color:this.props.viewingPage==='score'?'#BF2F1A':'white'}}>Scores</Text>
                                    </TouchableOpacity>
                                </Link>
                            </View>
                            <View style={{flex:1, }}>
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <TouchableOpacity style={{flex:1, alignItems:'center'}} 
                                     //   onPress = {() => {this.props.updateViewingPage('home')} } 
                                    >
                                        <FaHome size={30} color={this.props.viewingPage==='home'?'#BF2F1A':'white'}/>
                                        <Text style = {{color:this.props.viewingPage==='home'?'#BF2F1A':'white'}}>Home</Text>
                                    </TouchableOpacity>
                                </Link>
                            </View>
                            <View style={{flex:1}}>
                                <Link to={`/teams`} style={{ textDecoration: 'none' }}>
                                    <TouchableOpacity style={{flex:1, alignItems:'center'}} 
                                     //   onPress = {() => {this.props.updateViewingPage('teams')} }
                                        >
                                        <FaUsers size={30} color={this.props.viewingPage==='teams'?'#BF2F1A':'white'}/>
                                        <Text style = {{color:this.props.viewingPage==='teams'?'#BF2F1A':'white'}}>Schools</Text>
                                    </TouchableOpacity>
                                </Link>
                            </View>
                        </View>
                    </View>   
                               
                    : 
                    <View style={{ backgroundColor:  'black' , height:50, position: 'fixed', width: '100%', margin: 0,justifyContent:'center', top:0}}>
                        <View style={{flexDirection:'row',}}>
                                <View style={{flex:1, flexDirection:'row',}}>
                                    <View>
                                        <Text style={{color: '#AD3E3A', fontWeight:'bold', fontSize:23, fontFamily:  'Helvetica', paddingLeft:15,paddingRight:5 }}>AllAmerican</Text>                                
                                        <Text style={{color: 'white',  fontSize:13, fontFamily:  'Helvetica', paddingLeft:15,paddingRight:5 }}>Houston High School</Text> 
                                    </View>
                                    <View style={{alignItems:'center', flex:1, flexDirection:'row', }}>
                                        <Link to="/" style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{flex:1, alignItems:'center',paddingLeft:30}} 
                                            //onPress = {() => {this.props.updateViewingPage('home')} } 
                                                >                                        
                                                <Text style = {{color:this.props.viewingPage==='home'?'#D01811':'white', fontSize:16}}>Home</Text>
                                            </TouchableOpacity>
                                        </Link>

                                        <Link to="/score/" style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{flex:1, alignItems:'center', paddingLeft:30 }}
                                            //onPress = {() => {  this.props.updateViewingPage('score')} }
                                            >                                        
                                                <Text style = {{color:this.props.viewingPage==='score'?'#D01811':'white', fontSize:16}}>Scores</Text>
                                            </TouchableOpacity>
                                        </Link>                     
                                
                                        <Link to={`/teams`} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{flex:1, alignItems:'center', paddingLeft:30}} 
                                            //onPress = {() => {this.props.updateViewingPage('teams')} }
                                            >                                        
                                                <Text style = {{color:this.props.viewingPage==='teams'?'#D01811':'white', fontSize:16}}>Schools</Text>
                                            </TouchableOpacity>
                                        </Link>
                                    </View>
                                </View>
                                <View style={{marginRight:20,}}>
                                    
                                        <FaCog size={25} style={{alignSelf:'center', paddingTop:5}} color='white' onClick={() => {this.setState({ isPaneOpen: true })}} />
                                    
                                    <SlidingPane
                                        className='some-custom-class'
                                        overlayClassName='some-custom-overlay-class'
                                        isOpen={ this.state.isPaneOpen }
                                        title='Setting'
                                        width='400px'
                                        //subtitle='Optional subtitle.'
                                        onRequestClose={ () => {
                                            // triggered on "<" on left top click or on outside click
                                            this.setState({ isPaneOpen: false });
                                        } }>
                                    <FlatList                                 
                                            data={list}
                                            renderItem={this._renderItem }
                                            ItemSeparatorComponent={this.renderSeparator}     
                                            
                                        />
                                    </SlidingPane>
                                </View>
                        </View>
                    </View>
                    
                    
                    
                }
                
            </div>


            
            
            
        );
    }
}

const mapStateToProps = state => {
    return { 
        viewingPage: state.viewingPage,
        currentTeamSel: state.currentTeamSel,
             
    }  
}

export default connect(mapStateToProps, actions)(withRouter(props => <App{...props}/>));
//export default connect(mapStateToProps, actions)(App); 
//export default AppRouter;



//export default App;
