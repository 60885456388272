import * as React from 'react';
import { Platform, Text, View, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import { UpdateHomePage } from './actions';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { FaCog} from 'react-icons/fa';

import { FaLock} from 'react-icons/fa';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { IoMdText} from 'react-icons/io';
import { MdPlayArrow } from 'react-icons/md';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import NewsPage from './NewsPage';
import LinesEllipsis from 'react-lines-ellipsis';
import { platform } from 'os';
import amplitude from "amplitude-js";
import {AmplitudeProvider, Amplitude, LogOnMount, LogOnChange} from "@amplitude/react-amplitude";
import SlidingPane from 'react-sliding-pane';
import Modal from 'react-modal';
import 'react-sliding-pane/dist/react-sliding-pane.css';

 
//import { ListItem } from 'react-native-elements';

const moment = require('moment-timezone');
const AMPLITUDE_KEY = "265ecd4214dc923fea7ba20a854373ff";


class HomePage extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
            headlinesData:[], 
            classheadlinesData:[],           
            homePageIndex:0,
            settingPageIndex: 0,  
            skipindex:true,
            districtData:[],  
            currentHeadlinesData:[],   
            TrendingMainHeadingSizeWidth:Dimensions.get('window').width ,
            TrendingMainHeadingSizeHeight: 450, 
            Imageratio:1,
            isPaneOpen: false,
            isPaneOpenLeft: false,
            podCast:[] 
        } 
        
       
        //this.handler = this.handler.bind(this)   
        //this.skipUserInfo = this.skipUserInfo.bind(this)
        //const amplitude = new RNAmplitude('265ecd4214dc923fea7ba20a854373ff');
        //amplitude.logEvent('StartHomePage');  
    }

    AddNewToClass = (data) => {
        for ( var j in this.state.districtData) {       
            var districtDataTemp = [];
            for (var i in data)  {                 
                if ( data[i].schoolName.includes(this.state.districtData[j][0][0].class)) {                           
                    districtDataTemp.push(data[i])                     
                }                
                if (i==data.length-1) {                          
                    this.state.districtData[j][1] = districtDataTemp                            
                }
            }
            if (j == this.state.districtData.length-1) {                         
                    this.setState({districtData: this.state.districtData});
            }            
        }
       // this.setState({districtData: this.state.districtData});
    } 
    classifiedSchool = (data) => {        
        data.sort(function(a,b){return ('' + b.class).localeCompare(a.class)});  
        var sportTeamClass= [];
        for ( var i = 0; i < data.length; i++) { 
            if (data.length===1){                
                sportTeamClass = [[[data[i]]]]
            }
            else if (i==0){                
                var teamArray= [data[i]]
            }
            else {
                if (data[i-1]["class"] === data[i]["class"]){                    
                    teamArray.push(data[i])
                }
                else {                    
                    sportTeamClass.push([teamArray])                
                    teamArray= [data[i]]
                }
                if (data.length===i+1) {                    
                    sportTeamClass.push([teamArray])
                }
            }
        }
        
        this.setState({districtData: sportTeamClass});
        //return sportTeamClass    
       
    }

    HeadlinesFun = () => {                 
        const district = `
        query district  {
            district  {
              items{schoolName sports class}
            }
          }`;
        API.graphql(graphqlOperation(district)).then(response => {
           
            this.classifiedSchool(response.data.district.items)
           // this.props.updateCurrentSelectedTeam(response.data.district.items[0].schoolName)
            //if (this.state.classheadlinesData.length!=0) {
                this.AddNewToClass(this.state.classheadlinesData)
            //}
           // this.setState(() => {return {districtData: response.data.district.items}});
        }).catch(e => {console.log(e);});

        const headlines = `
            query Headlines {
                Headlines  { items { schoolName date topic story author readTime storyNo fileType} nextToken }
            }`;
        API.graphql(graphqlOperation(headlines)).then(response => {
           
            //this.props.UpdateNewPageIndex(0)
            //this.setState({currentHeadlinesData: response.data.Headlines.items})

            response.data.Headlines.items.sort(function(a,b){return Number(a.storyNo) - Number(b.storyNo)});
            this.setState({homePageIndex: 1, headlinesData:response.data.Headlines.items })
            var PageURL = window.location.href.split("/");
            function CheckIndex(element) {
                return element === 'home';
            }  
                  
            if (PageURL.includes('home')) { 
                //this.setState({currentHeadlinesData: response.data.Headlines.items})
                this.state.currentHeadlinesData.push(...response.data.Headlines.items)
                console.log(this.state.currentHeadlinesData)
                var newIndex = PageURL.findIndex(CheckIndex)
                this.state.currentHeadlinesData.find((o, i) => {  
                    if (o.topic === PageURL[newIndex+1].replace(/%20/g, " ").replace(/%E2%80%99/g, "'")) {
                        this.props.UpdateNewPageIndex(i)
                    }
                    
                });
            }
                
           
        }).catch(e => {console.log(e);});

        const classheadlines = `
            query ClassHeadlines {
                ClassHeadlines  { items { schoolName date topic story author readTime fileType} nextToken }
            }`;
        API.graphql(graphqlOperation(classheadlines)).then(response => {
           
            response.data.ClassHeadlines.items.sort(function(a,b){return moment(b.date) - moment(a.date)});
           
            //this.setState({homePageIndex: 1,})
            if (this.state.districtData.length!=0) {
                this.AddNewToClass(response.data.ClassHeadlines.items)
                this.setState({ classheadlinesData: response.data.ClassHeadlines.items })
            }
            else {
                this.setState({ classheadlinesData: response.data.ClassHeadlines.items })
            }
            var PageURL = window.location.href.split("/");
            function CheckIndex(element) {
                return element === 'home';
            }  
                  
            if (PageURL.includes('home')) {  
                this.state.currentHeadlinesData.push(...response.data.ClassHeadlines.items)
                var newIndex = PageURL.findIndex(CheckIndex)                
                this.state.currentHeadlinesData.find((o, i) => {                                                       
                    if (o.topic === PageURL[newIndex+1].replace(/%20/g, " ").replace(/%E2%80%99/g, "'")) {
                        this.props.UpdateNewPageIndex(i)
                    } 
                });
            }

                //this.state.headlinesData.sort(function(a,b){return moment(a.gameTime) - moment(b.gameTime)});
        }).catch(e => {console.log(e);});

        const podcast = `
        query PodCast {
            PodCast  { items { schoolName date topic story author readTime fileType} nextToken }
        }`;
        API.graphql(graphqlOperation(podcast)).then(response => {  
            console.log(response.data.PodCast.items)          
            var a = response.data.PodCast.items.sort(function(a,b){return moment(b.date) - moment(a.date)}); 
            this.setState({ podCast: response.data.PodCast.items })
        
        }).catch(e => {console.log(e);});

        

    }

    componentDidMount() {
        console.log(Platform)
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.HeadlinesFun();   
       // Modal.setAppElement(this.el);      
       // this.props.updateViewingPage('home')     
    }
    componentDidUpdate(prevProps) {
       // this.props.updateViewingPage('home')  
    }
    componentWillUnmount() {

    }
   handleResize = () => {
       console.log(Dimensions.get('window'))
       if (Dimensions.get('window').width<=770){
        this.setState({TrendingMainHeadingSizeWidth:Dimensions.get('window').width, Imageratio:Dimensions.get('window').width/700})
       }
       else {
       this.setState({TrendingMainHeadingSizeWidth:700, Imageratio:1})
       }
   }
  /*
   <LogOnChange value={this.props.currentTeamSel} eventType="play song" />
     eventProperties={inheritedProps => ({
                    ...inheritedProps,
                    "story": this.props.currentTeamSel,
                   
                  })}
    */
    /*
   ShowHomePage = () => {
  
        
            return (
                <div ref={ref => this.el = ref}>
                <button onClick={() => this.setState({ isPaneOpen: true })}>Click me to open right pane!</button>
                <div style={{ marginTop: '32px' }}>
                    <button onClick={ () => this.setState({ isPaneOpenLeft: true }) }>
                        Click me to open left pane with 20% width!
                    </button>
                </div>
                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='some-custom-overlay-class'
                    isOpen={ this.state.isPaneOpen }
                    title='Hey, it is optional pane title.  I can be React component too.'
                    subtitle='Optional subtitle.'
                    onRequestClose={ () => {
                        // triggered on "<" on left top click or on outside click
                        this.setState({ isPaneOpen: false });
                    } }>
                    <div>And I am pane content. BTW, what rocks?</div>
                    <br />
                    <img src='img.png' />
                </SlidingPane>
                <SlidingPane
                    closeIcon={<div>Some div containing custom close icon.</div>}
                    isOpen={ this.state.isPaneOpenLeft }
                    title='Hey, it is optional pane title.  I can be React component too.'
                    from='left'
                    width='200px'
                    onRequestClose={ () => this.setState({ isPaneOpenLeft: false }) }>
                    <div>And I am pane content on left.</div>
                </SlidingPane>
            </div>
            )
        
    
   }
   */

    renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "black",                    
                }}
            />
        );
    };

    _renderItem = ({ item, index }) => (
      
       
            <TouchableOpacity style={{marginBottom:20, marginTop:20,flexDirection:'row', justifyContent: 'center',alignContent:'center'}}>
             {index===0 ? <FaLock size={20} color='black'/> : <IoMdText size={25} color='black'/> }
            <Text onPress={() => Linking.openURL(item.link) }
                style={{ fontWeight:500, textAlign: 'center', fontSize: 15, color: 'black', flex:0.7 }}>{item.title}</Text>
            
            </TouchableOpacity>
          
    );
   
    ShowHomePage = () => {
        console.log(this.state.TrendingMainHeadingSizeWidth)
        const list = [
            {title: 'Privacy', icon: 'FaLock', link:'https://www.fanzquiz.com/privacy',},
            {title: 'FeedBack', icon: 'FaLock', link:'mailto:fanzquiz@gmail.com?subject=App Support',},
        ] 
        return (

        <div ref={ref => this.el = ref}>
                
            <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                 apiKey='265ecd4214dc923fea7ba20a854373ff'
               
                >
            <Amplitude
                eventProperties={{
                scope: ["Info"],
                "school name": "lamar",
                }}
                >  
            <LogOnMount eventType="HomePage" />
            
            <View style={{paddingBottom:30, marginTop:-20, backgroundColor:'#D0D3D5', }} >
              
               
                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='some-custom-overlay-class'
                    isOpen={ this.state.isPaneOpen }
                    title='Setting'
                    //subtitle='Optional subtitle.'
                    onRequestClose={ () => {
                        // triggered on "<" on left top click or on outside click
                        this.setState({ isPaneOpen: false });
                    } }>
                  <FlatList                                 
                        data={list}
                        renderItem={this._renderItem }
                        ItemSeparatorComponent={this.renderSeparator}     
                          
                    />
                </SlidingPane>
                
                <View style={{backgroundColor:'#D0D3D5',alignSelf:'center',width: isMobile? '100%' : this.state.TrendingMainHeadingSizeWidth, marginTop:10}} >
                    <View style={{backgroundColor: 'white', borderRadius:10,padding:10,}}> 
                        <Text style={{color: 'black', fontWeight:'bold', marginBottom:10, fontFamily:  'Helvetica'  }}>TRENDING</Text>
                        <View style={{borderBottomColor: '#B1B4B6', borderBottomWidth: 1, marginBottom:10,alignItems:'center',justifyContent:'center',
                                   
                                 }}/>
                        
                            {
                                this.state.headlinesData.length>0 ?
                               
                                        <Link to={`${this.props.match.url}home/${this.state.headlinesData[0].topic}`} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{backgroundColor: 'white',  borderRadius: 10,marginBottom:25, 
                                                shadowColor: '#000',shadowOffset: { width: 0.5, height: 0.5},shadowOpacity: 0.5,
                                                shadowRadius: 2, elevation: 4,  
                                            // minWidth:this.state.TrendingMainHeadingSizeWidth,
                                            //minHeight:this.state.TrendingMainHeadingSizeHeight,
                                                //maxWidth: 400,
                                           }} onPress={() => {this.props.UpdateNewPageIndex(0); this.setState({currentHeadlinesData: this.state.headlinesData})}} >                                                
                                                <ImageBackground style={{justifyContent:'center', borderTopRightRadius:10, borderTopLeftRadius:10, height: isMobile? 270 : 400*this.state.Imageratio, backgroundColor: '#8D9193', overflow:'hidden'}} 
                                                 source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + this.state.headlinesData[0].topic.trim().replace("?","")}}>
                                                   {
                                                        this.state.headlinesData[0].fileType != 'video' ?
                                                        null
                                                        :
                                                        <View style={{backgroundColor:'rgba(255,255,255, 0.7)',alignSelf:'center',borderRadius:20, }}>
                                                            <MdPlayArrow size={40} color='black' style={{alignSelf:'center'}} />  
                                                        </View>
                                                        
                                                    }    
                                                    
                                                    
                                                </ImageBackground>
                                                <Text style={{borderColor: '#B8BBBC', color: 'black',fontSize:17, paddingTop:10, marginBottom: 10, paddingLeft:15, paddingRight:15,fontfamily: 'Helvetica', fontWeight:500  }}>{this.state.headlinesData[0].topic}</Text> 
                                

                                                <Text style={{borderColor: '#B8BBBC', color: '#787A7C',fontSize:11, paddingTop:10, marginBottom: 10, paddingLeft:15, fontWeight:450}}>{this.state.headlinesData[0].date}</Text> 
                                            
                                            </TouchableOpacity>
                                        </Link>
                                 
                                :
                                    <View></View>
                            }
                      
                        {
                            this.state.headlinesData.length>1 ?
                            this.state.headlinesData.map(( item, key ) =>                
                                    (                                 
                                        key === 0 ?
                                        <View></View>
                                        :
                                        <Link to={`${this.props.match.url}home/${item.topic}/`} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{flexDirection:'row', marginBottom:20, backgroundColor: 'white', borderRadius: 10,                                 
                                               
                                            shadowColor: '#000',
                                                shadowOffset: { width: 0.5, height: 0.5 },
                                                shadowOpacity: 0.5,
                                                shadowRadius: 2,
                                                elevation: 4,}} onPress={() => {  this.props.UpdateNewPageIndex(key); this.setState({currentHeadlinesData: this.state.headlinesData})}}>
                                                 <ImageBackground style={{ alignSelf:'center',justifyContent:'center',borderBottomLeftRadius:10, borderTopLeftRadius:10, width: 160, height: 100, backgroundColor: '#8D9193', overflow:'hidden'}}
                                                 source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + item.topic.trim().replace("?","")}} >                   
                                                    {
                                                        item.fileType != 'video' ?
                                                        null
                                                        :
                                                        <View style={{backgroundColor:'rgba(255,255,255, 0.7)',alignSelf:'center',borderRadius:15, }}>
                                                            <MdPlayArrow size={30} color='black' style={{alignSelf:'center'}} />  
                                                        </View>
                                                        
                                                    }
                                                </ImageBackground>
                                                <View style ={{flex: 1,}}>                           
                                                    <Text numberOfLines={2} style = {{color:'black', marginTop: 10, marginLeft:15, flex: 1, marginRight:15, fontSize:15, flexWrap: 'wrap', fontfamily: 'Helvetica', fontWeight:500 }}>
                                                        <LinesEllipsis
                                                        text={item.topic}
                                                        maxLine='3'
                                                        ellipsis='...'
                                                        trimRight
                                                        basedOn='letters'
                                                        />
                                                    </Text>
                                                    <Text style = {{color:'#787A7C', marginTop: 10, marginBottom: 5, marginLeft:15,  marginRight:15, fontSize:12, flexWrap: 'wrap', fontWeight:500 }}>{item.date}</Text>
                                                   
                                                </View>
                                                
                                            </TouchableOpacity> 
                                        </Link>                               
                                    )
                                )
                            :
                                <View></View>
                        }
                    </View> 
                    {
                        this.state.districtData.length===0 ? null : this.state.districtData.map((item, key) => 
                            (
                            <View style={{backgroundColor: 'white', borderRadius:10,padding:10,marginTop:20}}> 
                                <Text style={{color: 'black', fontSize:15, fontWeight:'bold', marginBottom:7, fontFamily: Platform.OS === 'ios'? 'Helvetica' : 'sans-serif-medium',}}>{item[0][0].class}</Text> 
                                <ScrollView horizontal={true} style={{paddingTop:0,}}>
                                    {
                                        item[0].map((item, key1)  => 
                                            (     
                                                <Link to={`${this.props.match.url}teams`} style={{ textDecoration: 'none' }}>                                 
                                                    <TouchableOpacity style={{width: 60, height: '85%', marginEnd:12, borderRadius: 10,paddingBottom:10,marginTop:10, 
                                                    }} 
                                                    onPress={() => { this.props.updateCurrentSelectedTeam(item.schoolName)
                                                    }}>           
                                                        <Image
                                                            style={{width: 40, height: 40,  alignSelf: 'center',marginTop:7}}
                                                            source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + item.schoolName.split('-')[0] + ' - ' + item.schoolName.split('-')[1]+ '/logo' , cache: 'default'}} >                                        
                                                        </Image>                                       
                                                        <Text style = {{fontfamily: 'Helvetica',color:'#787A7C', alignSelf: 'center', marginTop:5, fontSize: 11, fontWeight:700 }}>{item.schoolName.substring(0,3).toUpperCase() }</Text>                                                
                                                    </TouchableOpacity>  
                                                </Link> 
                                                                                                    
                                            )
                                        )
                                    }
                                </ScrollView>

                                <ScrollView horizontal={true} style={{paddingTop:20, paddingBottom:5,}}>
                                    {                                                
                                        item.length <= 1 ? null:                                                             
                                        item[1].map(( item, key, Array) =>                
                                            (      
                                                <Link to={`${this.props.match.url}home/${item.topic}/`} style={{ textDecoration: 'none' }}> 
                                                    <TouchableOpacity style={{ backgroundColor: 'white', width: 250, height: '95%', marginEnd:20,  borderRadius: 10,
                                                    marginLeft:5, shadowRadius: 2, elevation: 4,
                                                    shadowColor: '#000', shadowOffset: { width: 0.5, height: 0.5}, shadowOpacity: 0.5,}} 
                                                    onPress={() => {this.props.UpdateNewPageIndex(key); this.setState({currentHeadlinesData: Array})}}>                       
                                                    
                                                        <ImageBackground style={{ justifyContent:'center', borderTopRightRadius:10, borderTopLeftRadius:10, width: 250, height: 140, backgroundColor: '#8D9193', overflow:'hidden'}} 
                                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + item.topic.trim().replace("?",""),cache: 'reload'}}>
                                                        {
                                                            item.fileType != 'video' ?
                                                            null
                                                            :
                                                            <View style={{backgroundColor:'rgba(255,255,255, 0.7)',alignSelf:'center',borderRadius:15, }}>
                                                                <MdPlayArrow size={30} color='black' style={{alignSelf:'center'}} />  
                                                            </View>
                                                        
                                                        }
                                                        </ImageBackground>
                                                        <View style={{padding:5, marginTop:7, marginBottom:15, }}>
                                                            <Text numberOfLines={2} style = {{ fontfamily: 'Helvetica' , color: 'black',fontSize: 15,fontWeight:500 }}>
                                                            <LinesEllipsis
                                                                text={item.topic}
                                                                maxLine='2'
                                                                ellipsis='...'
                                                                trimRight
                                                                basedOn='letters'
                                                                />
                                                            </Text>
                                                            <Text style = {{color: '#787A7C', marginTop:20, fontSize: 11,fontWeight:500 }}>{item.date}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </Link> 
                                            )                                                    
                                        )                                                     
                                    } 

                                               
                                </ScrollView>  
                            
                            </View> 
                            )
                        )
                    }  
                        <View style={{backgroundColor: 'white', borderRadius:10,padding:10,marginTop:20}}> 
                                        <Text style={{color: 'black', fontSize:15, fontWeight:'bold', marginBottom:13, fontFamily: Platform.OS === 'ios'? 'Helvetica' : 'sans-serif-medium',}}>PodCast</Text> 
                                        <ScrollView horizontal={true} style={{marginTop:10, paddingBottom:5,}}>
                                                    {                                                
                                                        this.state.podCast.length === 0 ? null:                                                             
                                                        this.state.podCast.map(( item, key, Array) =>                
                                                        (      
                                                            <Link to={`${this.props.match.url}home/${item.topic}/`} style={{ textDecoration: 'none' }}> 
                                                                <TouchableOpacity style={{ backgroundColor: 'white', width: 250, height: '95%', marginEnd:20,  borderRadius: 10,
                                                                marginLeft:5, shadowRadius: 2, elevation: 4,
                                                                shadowColor: '#000', shadowOffset: { width: 0.5, height: 0.5}, shadowOpacity: 0.5,}} 
                                                                onPress={() => {this.props.UpdateNewPageIndex(key); this.setState({currentHeadlinesData: Array})}}>                       
                                                                <ImageBackground style={{ justifyContent:'center', borderTopRightRadius:10, borderTopLeftRadius:10, width: 250, height: 140, backgroundColor: '#8D9193', overflow:'hidden'}} 
                                                                    
                                                                    source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + item.topic.trim().replace("?",""),cache: 'reload'}}>
                                                                    {
                                                                        item.fileType != 'video' ?
                                                                        null
                                                                        :
                                                                        <View style={{backgroundColor:'rgba(255,255,255, 0.7)',alignSelf:'center',borderRadius:15, }}>
                                                                            <MdPlayArrow size={30} color='black' style={{alignSelf:'center'}} />  
                                                                        </View>
                                                                    
                                                                    }
                                                                    </ImageBackground>
                                                                    <View style={{padding:5, marginTop:7, marginBottom:15, }}>
                                                                        <Text numberOfLines={2} style = {{ fontfamily: 'Helvetica' , color: 'black',fontSize: 15,fontWeight:500 }}>
                                                                        <LinesEllipsis
                                                                            text={item.topic}
                                                                            maxLine='2'
                                                                            ellipsis='...'
                                                                            trimRight
                                                                            basedOn='letters'
                                                                            />
                                                                        </Text>
                                                                        <Text style = {{color: '#787A7C', marginTop:20, fontSize: 11,fontWeight:500 }}>{item.date}</Text>
                                                                    </View>
                                                                </TouchableOpacity>
                                                            </Link> 
                                                        )                                                   
                                                        )                                                     
                                                    }
                                        </ScrollView>
                                    </View>                                             
                </View>
               
              
                
                </View>
            </Amplitude>  
            </AmplitudeProvider>
            </div>
            )
    }

    
    /*
    NewPagFun = () => {     
        console.log(this.props)
        return (
                        
               // <NewsPage {...this.state.currentHeadlinesData[this.props.newPageIndex]}  />
                  
                <Text style={{backgroundColor:'orange', height:100}}>this is happening</Text>
                
        );               
    }
    */
    // <Route path='df' component={this.NewPagFun()} />
    render() {
        console.log(this.state)
        console.log(this.props)
        
        
        
        return (
        
        <div>
            
                <Route  path={`${this.props.match.path}home/:id`}
                render={(props) => < NewsPage {...this.state.currentHeadlinesData[this.props.newPageIndex]}  />}
                    />
            
                <Route
                    exact
                    path={this.props.match.path}
                    render={() => <h3>{this.ShowHomePage()}</h3>}
                />
            
        
          
          
        </div>
        )
    }
    
}

const mapStateToProps = state => {
    return { 
        currentTeamSel: state.currentTeamSel,
        newPageIndex : state.newPageIndex,  
        userLoginInfoReducer: state.userLoginInfoReducer, 
        teamNewData: state.teamNewData,  
        newPageIndex : state.newPageIndex,      
    }  
}

export default connect(mapStateToProps, actions)(HomePage);
  