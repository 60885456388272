import { combineReducers } from 'redux';
import currentTeamSelectedReducer from './currentTeamSelectedReducer';

import newPageReducer from './newPageReducer';
import newPageIndex from './showNewPageReducer';
import playerPageindex from './playerIndexReducer';
import fixtureData from './fixtureData';
import rosterData from './rosterData';
import nextGameElement  from './nextGameElement';
import propsElement  from './propsElement';
import playerDetailInfo from './playerDetailInfo';
import standingData from './standingData';
import teamNewData from './teamNewData';
import userLoginInfoReducer from './userLoginInfoReducer';
import viewingPage from './viewingPage';
import codeInProgressReducer from './codeInProgressReducer';
import homeTeamStats from './homeTeamStats';
import awayTeamStats from './awayTeamStats';
import playMatrix from './playMatrix';

export default combineReducers({
    currentTeamSel: currentTeamSelectedReducer,  
    newPage: newPageReducer,
    newPageIndex : newPageIndex,
    playerPageindex: playerPageindex,
    fixtureData: fixtureData,
    nextGameElement : nextGameElement,
    propsElement :  propsElement,
    rosterData: rosterData,    
    standingData: standingData,
    playerDetailInfo: playerDetailInfo,
    teamNewData: teamNewData,  
    userLoginInfoReducer: userLoginInfoReducer,
    viewingPage: viewingPage,
    codeInProgressReducer:codeInProgressReducer,
    homeTeamStats: homeTeamStats,
    awayTeamStats: awayTeamStats,
    playMatrix: playMatrix
    
})