import { connect } from 'react-redux';
import * as actions from './actions';
import React, { Component } from 'react';
import { FaCog} from 'react-icons/fa';
import {Platform,StyleSheet,Text,View, ScrollView, Dimensions, BackHandler, Image, ImageBackground,TouchableOpacity} from 'react-native';
import amplitude from "amplitude-js";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";
//import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
const moment = require('moment-timezone');

class PlayerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            playerDetailHeading:['Height', 'Weight', 'Position', 'Specialty'],
            playerDetailUnit:['', 'lbs', '', ''],
            PageSizeWidth:Dimensions.get('window').width 
        }
    }
    
    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
            //this.props.UpdateHomePage(1); 
            //this.props.UpdatePlayerIndex(-1);           
          
    }
    
    componentWillUnmount() {
        console.log('unmount MainPage')
         
    }

    handleResize = () => {
        console.log(Dimensions.get('window'))
        if (Dimensions.get('window').width<=600){
        this.setState({PageSizeWidth:Dimensions.get('window').width, })
        }
        else {
        this.setState({PageSizeWidth:600,})
        }
    }
    
    render () {
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')  
        console.log(headerTitle)
        
        if (this.props.playerPageindex != -1) { 
            return (
                <AmplitudeProvider
                    amplitudeInstance={amplitude.getInstance()}
                    apiKey='265ecd4214dc923fea7ba20a854373ff'               
                    > 
                    <Amplitude
                        eventProperties={{                        
                        "School Name": headerTitle.split('-')[0],  
                        }}
                         >                 
                     <LogOnMount eventType="PlayerPage" />
                    <View style={{flex:1,backgroundColor:'#D0D3D5',   height: Dimensions.get('window').height }} >
                    
                        
                        <View style={{backgroundColor:'black', marginTop:10,  width: isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',marginBottom:60}}>
                            
                            <ScrollView  style={{ flex:1}}>
                                <Text style={{fontWeight:600, fontfamily:'Helvetica',color:'white', fontSize:22, fontWeight: '400', textAlign: 'center', margin: 10}}>{headerTitle.split('-')[0] + ' - ' + headerTitle.split('-')[1]}</Text> 
                                    <ImageBackground style={{ width: 120, height: 120, borderRadius: 60,alignSelf: 'center',}} source={{uri: 'https://www.integraconference.info/wp-content/uploads/2018/03/placeholder-face-big.png'}}>
                                    <Image
                                        style={{width: 120, height: 120, borderRadius: 60,alignSelf: 'center', }}
                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + headerTitle.split('-')[0] + ' - ' + headerTitle.split('-')[1].split(" ")[0] + '/' 
                                        + headerTitle.split('-')[1] + '/roster/' +  this.props.rosterData.players[this.props.playerPageindex].slice(this.props.rosterData.players[this.props.playerPageindex].split(' ',7).join('').length+7,this.props.rosterData.players[this.props.playerPageindex].split('[<>]',2).join('').length+4)}}>
                                    </Image>
                                    </ImageBackground>         
                                    <Text style={{fontWeight:470, fontfamily:'Helvetica',color:'white', fontSize:19, marginTop:10, textAlign: 'center', }}>{this.props.rosterData.players[this.props.playerPageindex].split(' ', 8)[7] + 
                                    (this.props.rosterData.players[this.props.playerPageindex].split('[<>]').length>2? ' AKA ' + this.props.rosterData.players[this.props.playerPageindex].slice(this.props.rosterData.players[this.props.playerPageindex].split('[<>]',2).join('').length+8): '')
                                    }</Text>
                                    <Text style={{fontWeight:470, fontfamily:'Helvetica',color:'#8D9193', fontSize:15, marginBottom:10, textAlign: 'center',}}>{this.props.rosterData.players[this.props.playerPageindex].split(' ')[5] + '/' + this.props.rosterData.players[this.props.playerPageindex].split(' ')[6]}</Text>
                                    { 
                                    this.props.playerDetailInfo[this.props.playerPageindex].split(' ').map(( item, key ) =>
                                        (
                                        <View style={{flex: 1, flexDirection: 'row', marginTop:5, marginBottom: 5, paddingLeft:10, paddingRight:10}}>
                                            <Text style={{fontWeight:470, fontfamily:'Helvetica',color:'white', fontSize:15, marginTop:10,  }}>{this.state.playerDetailHeading[key]}</Text> 
                                            <Text style={{fontWeight:470, fontfamily:'Helvetica', color:'white', fontSize:15, marginTop:10, flex: 1, textAlign: 'right', }}>
                                         {//item + ' ' + this.state.playerDetailUnit[key] + (this.state.playerDetailHeading.length-1 !=key?'' :' (Age ' + (item === '-' ? ')':moment().diff(moment(item, 'YYYY-MM-DD'), 'years') + ')' ))
                                           item.replace("-"," ") + ' ' + (key > 3 ? ' ': this.state.playerDetailUnit[key])
                                         }
                                            </Text>
                                        </View>
                                        )
                                    )
                                    }
                            
                            </ScrollView>                 
                        </View> 
                    </View> 
                    </Amplitude> 
                </AmplitudeProvider>  

            );
        }
        else return null 
    }
}

const mapStateToProps = state => {
    return {  
        currentTeamSel: state.currentTeamSel,      
        rosterData : state.rosterData,     
        playerPageindex : state.playerPageindex,  
        playerDetailInfo : state.playerDetailInfo,    
    }
}
export default connect(mapStateToProps, actions)(PlayerPage);