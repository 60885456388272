import React, { Component, Fragment } from 'react';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {Platform,Text,View, ScrollView, Dimensions,Image,TouchableOpacity} from 'react-native';
//import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import * as actions from './actions';
import { FaFootballBall } from 'react-icons/fa';
import { FaBaseballBall } from 'react-icons/fa';
import { FaBasketballBall } from 'react-icons/fa';
import { FaCog} from 'react-icons/fa';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";

import { FaFutbol } from 'react-icons/fa';
import { FaVolleyballBall } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import MainPage from './MainPage';
//import { ScrollingProvider, Section } from "react-scroll-section";
import './style.css';


//import NewsPage from './NewsPage';
//import PlayerPage from './PlayerPage';
const moment = require('moment-timezone');
//import Icon5 from 'react-native-vector-icons/FontAwesome5';



const { width: SCREEN_WIDTH } = Dimensions.get('screen')
const { width, height } = Dimensions.get('window');
const widthScale = width / 320;

//import { SocialIcon, Card, Button, Avatar, } from 'react-native-elements';

class SelectTeam extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            districtData:[],          
            count: 0,  
            buttonPressed: 0,  
            dialogVisible: false,
            s_team:0,
          
            scrollItemIndex : 0, 
            sport :'',
            passvar:'',
            PageSizeWidth:Dimensions.get('window').width 
               
        }; 
        this.s_team = this.backButton.bind(this);
        //this.buttonPressed = this.checkforSelectedDistrict.bind(this)
       // var s_team = this.s_team.bind(this) ;
      
    }  

    backButton(on) {          
        this.setState({ s_team: 0 });
    }
    
    /*
     query Schedule {
                    Schedule (homeTeam:"Sharpstown - 5A - soccer" )  {
                        items{ gameTime awayTeam homeTeam awayTeamScore homeTeamScore time sport gameStatus}
                    }
                }
    */
   
      
      
    handleClick = (teamName ) => { 
        
        this.setState({ s_team: 1, });   
        
        this.props.UpdateHomePage(1); 
        this.props.UpdateNewPageIndex(-1)  
        //var passvar = this.state.districtData[this.state.buttonPressed].schoolName.split('-')[0] + ' - ' + this.state.districtData[this.state.buttonPressed].schoolName.split('-')[1] 
        console.log(this.state.districtData[this.state.buttonPressed].schoolName.split('-')[0] + '-' + teamName)
        var passvar = this.state.districtData[this.state.buttonPressed].schoolName.split('-')[0] + '-' + this.state.districtData[this.state.buttonPressed].schoolName.split('-')[1] 
        this.setState({ sport: teamName, passvar: passvar}); 
    
        this.props.updateCurrentSelectedTeam(this.state.districtData[this.state.buttonPressed].schoolName + ' ' + teamName)
        
        /*
        const navigateAction = NavigationActions.navigate({
            routeName: 'Home',
            params: {},
            action: NavigationActions .navigate({ routeName: 'Home' }),
        });

        this.props.navigation.dispatch(navigateAction);         
       */
    }


    collapseLeq = (index) => {
        if (this.state.buttonPressed === index) {
            this.setState({ buttonPressed: -1 })
        }
        else {
            this.setState({ buttonPressed: index })
        }
    }

    UpdateDictrictData = () => {
        const district = `
          query district  {
            district  {
              items{schoolName sports}
            }
          }`;
        API.graphql(graphqlOperation(district)).then(response => {
            console.log(response.data.district.items)
            this.setState({districtData: response.data.district.items});

            
            //const { navigation } = this.props;
            //const otherParam = navigation.getParam('otherParam', 0); 
           
           // if (this.props.currentTeamSel === '') {                
                this.checkforSelectedDistrict(this.props.currentTeamSel);
           // }
            
        }).catch(e => {console.log(e);});      
    }

    checkforSelectedDistrict = (otherParam) => {          
        for (var i in this.state.districtData) {            
            if (otherParam === this.state.districtData[i].schoolName) {                   
                this.setState({ buttonPressed: Number(i), scrollItemIndex: Number(i) })                
                this.scrollViewRef.scrollTo({ y: 70*Number(i)  });                
                break
            }
        }             
    }
    
    componentDidMount() {
        //window.location.href
       // this.props.history.push('/teams/path');
       console.log("teamPageSelected")
       this.handleResize()
       window.addEventListener('resize', this.handleResize);
       // this.props.updateViewingPage('teams')
        this.UpdateDictrictData();        
                    
    }

    componentWillUnmount() {
       // this._navListener.remove();              
    }   
    
    handleResize = () => {
        console.log(Dimensions.get('window'))
        if (Dimensions.get('window').width<=600){
         this.setState({PageSizeWidth:Dimensions.get('window').width, })
        }
        else {
        this.setState({PageSizeWidth:600,})
        }
    }
    
   sportImage = (children ) => {      
        switch (children) {
            case 'Soccer':
                return <FaFutbol size={50} color='#b3b3b3' />              
            case 'Football':
                return <FaFootballBall size={50} color='#b3b3b3' />                
            case 'Baseball':
                return <FaBaseballBall size={50} color='#b3b3b3' />                 
            case 'Volleyball':
                return <FaVolleyballBall size={50} color='#b3b3b3' />             
            case 'Softball':
                return <FaBaseballBall size={50} color='#b3b3b3' />  
            case 'Basketball':
                return <FaBasketballBall size={50} color='#b3b3b3' />              
            default:
                return  null        
         }  
    }
   
    

    scrollToInitialPosition = () => {
        
        this.scrollViewRef.scrollTo({ y: 70*this.state.scrollItemIndex  });
       // this.scrollViewRef.scrollTo({ y: 70*item  });
      } 

    
   
    listTeamItems = () => {
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')  ;
        return (
        <View style={{paddingBottom:30, marginTop:-20, backgroundColor:'#D0D3D5', paddingBottom:10 }} >
              
            
            <View style={{  marginTop:10, width:isMobile ? '100%' : this.state.PageSizeWidth, alignSelf:'center', backgroundColor: 'white', backgroundColor: 'white', borderRadius:10, paddingTop:10, paddingBottom:10,marginBottom:30}} >            
               
                    
                <View style = {{ flexDirection:'row', height:height-100,  }}>  

                    <ScrollView style = {{ flexDirection:'column',flex:1, paddingBottom:0, backgroundColor:'white'}} 
                    ref={(ref) => { this.scrollViewRef = ref; }}
                    onLayout={this.scrollToInitialPosition} >
                            {    
                                                       
                                this.state.districtData.length != 0  ? this.state.districtData.map((item, index) =>             
                                         
                                        <TouchableOpacity onPress={() => {this.setState({ buttonPressed: index });this.props.updateCurrentSelectedTeam(item.schoolName)} } activeOpacity={.8} style={{marginBottom:1,backgroundColor: this.state.buttonPressed === index ? 'white' : '#909497', justifyContent: 'center', flex: 1,  height:100, alignItems:'center',paddingTop:20, paddingBottom:20}}>
                                            {console.log('https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + item.schoolName.split('-')[0] + ' - ' + item.schoolName.split('-')[1]+ '/logo')}
                                            <Image style={{ width: 40, height: 40, }}
                                                source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + item.schoolName.split('-')[0] + ' - ' + item.schoolName.split('-')[1]+ '/logo'}} /> 
                                            <Text style={{ fontSize:15, fontFamily: 'Helvetica' , color: this.state.buttonPressed === index ? 'black' : 'white'}}>
                                                {item.schoolName.split('-')[0] + ' - ' + item.schoolName.split('-')[1]}     
                                            </Text>            
                                        </TouchableOpacity>  
                                        
                                    
                                ) : []
                            }
                    </ScrollView>
                
                    <View style = {{ flex: 1.5,  padding:10,  alignItems:'center', backgroundColor:'white'}}>
                        
                
                        {
                        
                        this.state.districtData.length != 0  ? this.state.districtData[this.state.buttonPressed].sports.map((item, index) =>                        
                            <Link to={`${this.props.match.url}/${this.state.passvar}/${item}`} style={{ textDecoration: 'none' }}> 
                                    
                                    <TouchableOpacity  onPress={() => {this.handleClick(item.split('-')[0])}} style={{width:110,alignItems:'center',borderRadius:10, margin:2, padding:10, borderColor: '#D0D3D5',  borderWidth:1 }}>
                                        {this.sportImage(item.split('-')[0])}                                       
                                        <Text style ={{color: 'black', fontSize:16,  textAlign: 'center',textAlignVertical: 'center',}}> {item.split('-')[0] + ' '  + (item.split("-").length===3 ? item.split("-")[2] : '')} </Text>    
                                    </TouchableOpacity>    
                            </Link>                                       
                            
                            ) : []
                            
                        }
                
                    
                    </View>
                            
                </View>  
                            
            </View> 
        </View> 
       
        ) 
    }    

    render() {
        let { height } = Dimensions.get("window");    
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')  ;
        /*
        const { navigation } = this.props;
        const otherParam = navigation.getParam('otherParam', -1); 
        if (otherParam != -1) {
            this.setState({ buttonPressed: otherParam  })
        }      
        */
       console.log(this.props.currentTeamSel.split('_').join(' '))
       return (
       
        <div>  
            <Route  path={`${this.props.match.path}/:id/:id`}
              render={(props) => <MainPage routeProps={props} sport={this.state.sport} passvar={this.state.passvar}/>}
                 />     
            <Route
                exact
                path={this.props.match.path}
                render={() => <h3>{this.listTeamItems()}</h3>}
            />         
        </div>
        )
    {/*

        if (this.state.s_team===0) {
          
            return (            
                <View style={{ height: height - 100, paddingBottom:27 }} >            
                    <View style={{flexDirection:'row', padding:10,  paddingTop: 10, backgroundColor:'black'}}>
                        <Text style={{color: 'white', fontSize:18, flex:1, fontWeight:'bold'}}>Schools</Text>               
                    </View>  
                    <View >            
                        {this.listTeamItems()}                   
                    </View>                   
                </View>                
            )
        } 

        else  if (this.props.homePageStatus) {
            
            return (
                <View style={{ height: height,}}>
                    <MainPage navigation={this.props.navigation} backButtom = {() => this.backButton()}/> 
                    <TouchableOpacity onPress={() => {this.navigationdispatch(true); this.setState({ s_team: 0, });}} style={{margin: 30,position: "absolute",top: 0,left: 0,}}>
                        <IconMaterialCommunity iconStyle='Regular Style' name='arrow-left' size={30} color='white' />
                    </TouchableOpacity>
                </View>
            )
        }
        else if (this.props.newPageIndex != -1){                 
               return (
               <View style = {{backgroundColor: "white"}}> 
                    <TouchableOpacity style={{padding: 10, backgroundColor: 'black'}} onPress={() => { this.props.UpdateHomePage(1);  this.props.UpdateNewPageIndex(-1)}}>
                            <IconMaterialCommunity iconStyle='Regular Style' name='arrow-left' size={30} color='white' />
                    </TouchableOpacity>      
                    <NewsPage {...this.props.teamNewData[this.props.newPageIndex]}/>        
                        
               </View>                 
               );     
   
        }
        else if (this.props.playerPageindex != -1 ) {            
               return (
               <View style={{backgroundColor:'black', flex:1}}>
                    <PlayerPage />               
               </View>
               
               );
        }
        else {
            return (
                <View style={{ height: height - 100 }}>                   
                </View>
            )
        }
        */} 
    } 
  
}

const mapStateToProps = state => {
    return {        
        currentTeamSel: state.currentTeamSel,
        playerPageindex : state.playerPageindex,
        homePageStatus: state.newPage,        
        rosterData : state.rosterData,
        propsElement : state.propsElement,
        fixtureData : state.fixtureData,        
        teamNewData: state.teamNewData,  
        newPageIndex : state.newPageIndex,        
    }
}
export default connect(mapStateToProps, actions)(SelectTeam);