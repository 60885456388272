export const updateCurrentSelectedTeam = (Team) => {
    return {
        type: 'Cur_sel_Team',
        payload: Team
    };
};
export const UpdateHomePage = (booleen) => {
    return {
        type: 'updateHome_Info',
        payload: booleen
    };
};

export const UpdateNewPageIndex = (booleen) => {
    return {
        type: 'newInfoIndex',
        payload: booleen
    };
};

export const UpdatePlayerIndex = (booleen) => {
    return {
        type: 'playerIndex',
        payload: booleen
    };
};

export const updateGameFixtures = (fixtures) => {
    return {
        type: 'gameFixtures',
        payload: fixtures
    };
};



export const updateGameStanding = (standing) => {
    return {
        type: 'gameStanding',
        payload: standing
    };
};

export const updatePlayerDetailInfo = (playerInfo) => {
    return {
        type: 'player_detail',
        payload: playerInfo
    };
};

export const updateGameRosters = (rosters) => {
    return {
        type: 'gameRosters',
        payload: rosters
    };
};

export const updateTeamNewsData = (news) => {
    return {
        type: 'teamNews',
        payload: news
    };
};
export const updateNextGameElement = (element) => {
    return {
        type: 'next_game_element',
        payload: element
    };
};

export const updatePropsElement = (element) => {
    return {
        type: 'props_element',
        payload: element
    };
};

export const updateUserLoginInfo = (element) => {
    return {
        type: 'User_Login_Info',
        payload: element
    };
};

export const updateViewingPage= (element) => {
    return {
        type: 'viewing_page',
        payload: element
    };
};

export const updateCodeInProgress= (element) => {
    return {
        type: 'progress',
        payload: element
    };
};

export const updateHomeTeamStats= (element) => {
    return {
        type: 'home_team_stats',
        payload: element
    };
};

export const updateAwayTeamStats= (element) => {
    return {
        type: 'away_team_stats',
        payload: element
    };
};

export const updatePlayMatrix= (element) => {
    return {
        type: 'play_matrix',
        payload: element
    };
};


