// src/store/index.js

import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducers from './reducers';// the value from combineReducers

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    blacklist: ['teamNewData', 'homePageIndex', 'newPageIndex'],
    whitelist: ['userLoginInfoReducer']
    
};

const pReducer = persistReducer(persistConfig, reducers);

const configStore = async () => {
    let store = createStore(pReducer);
    let persistor = persistStore(store);
    return { persistor, store };
};
export default configStore;