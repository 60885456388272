import React, { Component } from 'react';
import { Platform, Text, StyleSheet, View, Image, ImageBackground, ScrollView, TouchableOpacity, Button,Dimensions} from 'react-native';
import { FaCaretLeft} from 'react-icons/fa';
//import IconA from 'react-native-vector-icons/FontAwesome';
//import { Button, Divider  } from 'react-native-elements';
import Amplify, { API, graphqlOperation, Auth, } from 'aws-amplify';
import * as actions from './actions';
import { connect } from 'react-redux';
import { FaCog} from 'react-icons/fa';
import amplitude from "amplitude-js";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { StickyContainer, Sticky } from 'react-sticky';
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";
//import moment from "moment";
const moment = require('moment-timezone');

//fontFamily: 'sans-serif-medium', 
class Score extends Component {
    constructor(props) {
        super(props);  
        this.state = { 
            scoresData:[], 
            weekButtonStyle:['#E5E7E8','white','#E5E7E8'], 
            pageNo: 1, 
            PageSizeWidth:Dimensions.get('window').width,
            codeinProgress: false,
            stopcode:false,
            StandingResult:[]
        };  
       // this.inwork = this.ScoreData.bind(this);     
        //this.stopcode = this.ScoreData.bind(this);   
    }

    componentDidMount() {
       /// console.log("scorepage")
        //this.props.updateViewingPage('score')
        this.ScoreData(1)
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
        this.StandingData()
    }
    
    componentWillUnmount() {
        
    }
    
    handleResize = () => {
       
        if (Dimensions.get('window').width<=600){
        this.setState({PageSizeWidth:Dimensions.get('window').width, })
        }
        else {
        this.setState({PageSizeWidth:600,})
        }
    }

    ScoreData = (weekIndex) => {        
        this.state.scoresData = []  
        this.setState( {scoresData: []});      
       if (weekIndex===0) {var startDate = -7}
       else if (weekIndex===1) {var startDate = 0}
       else {var startDate = 7}
        for (var i = 1; i < 8; i++) {        
            var gameTime = moment(moment().startOf('isoWeek')).day(i+startDate).format("YYYY-MM-DD")             
            moment.updateLocale('en', {week: {dow : 1,} });             
            const Scores = `
                query Scores($gameTime: String!) {
                    Scores (gameTime: $gameTime)  {
                        items{ gameTime awayTeam homeTeam awayTeamScore homeTeamScore time sport gameStatus venue}
                    }
                }`;
                API.graphql(graphqlOperation(Scores,{gameTime: gameTime})).then(response => {                    
                   if (response.data.Scores.items.length!=0){                     
                    if (moment(response.data.Scores.items[0].gameTime).isSame(moment(gameTime), 'week'))  { 
                       if (this.state.scoresData.length===0){                            
                            this.state.scoresData.push(response.data.Scores.items)
                       }
                       else { 
                           if (moment(response.data.Scores.items[0].gameTime).isSame(moment(this.state.scoresData[0][0].gameTime), 'week')){
                                for (i = 0; i<this.state.scoresData.length;i++) {                                                             
                                    if (response.data.Scores.items[0].gameTime > this.state.scoresData[i][0].gameTime ){                                       
                                        if (this.state.scoresData.length === i+1) {
                                                this.state.scoresData.push(response.data.Scores.items)
                                                break;
                                        }
                                        else {
                                                continue;
                                        }
                                    }
                                    else if (response.data.Scores.items[0].gameTime === this.state.scoresData[i][0].gameTime) {
                                                break;
                                    }
                                    else {
                                        this.state.scoresData.splice(i,0,response.data.Scores.items)  
                                        this.setState(() => {return {scoresData: this.state.scoresData}});                                 
                                        break;
                                    }                                
                                }
                           }
                           else {
                            this.setState( {scoresData: []});
                            this.state.scoresData.push(response.data.Scores.items)                            
                           }                         
                        }   
                    }  
                                 
                } 
                    //console.log(this.state.scoresData)                  
                   this.setState(() => {return {scoresData: this.state.scoresData}});  
                    }).catch(e => {console.log(e);});               
            }  
            //console.log(this.state.scoresData)       
    }

    StandingData = () => {  
        const regionName  = ['11-5A-Football', '9-5A-Football', '11-4A-Football', '12-4A-Football', '18-6A-Football', '12-4A2-Football',
                         '17-6AR3-Football','11-4AR3D1-Football','12-5AR3D2-Football','10-5AR3D1-Football']
        var StandingData = []
        for (var i = 0; i < regionName.length; i++)   {
        
            this.props.updateGameStanding([])
            const standing = `
            query standing ($regionName: String!) {
                standing (regionName: $regionName)  {
                items{regionName points stats schoolName}
                }
            }`;
            API.graphql(graphqlOperation(standing, { regionName: regionName[i] })).then(response => { 
               var distictInfo = {distict: response.data.standing.items[0].regionName, standingData : response.data.standing.items}
               StandingData.push(distictInfo)
                this.setState({StandingResult: StandingData});
                //console.log(this.state.StandingResult)
            }).catch(e => {console.log(e);});
        }
    }

    getTheTeamStanding = (TeamName, sport) => {
        var sixA = ['Bellaire', 'Carnegie', 'Chavez', 'Heights', 'Houston', 'Lamar', 'Westbury', 'Westside', 'Leland', 'Carnegie' ]
        var ElevenfiveA= ['Madison', 'Manvel', 'Marshall', 'Milby', 'Northside', 'Sharpstown', 'Sterling', 'Waltrip', 'Willowridge']
        var NinefiveA = ['Austin', 'Galena Park', 'Goose Creek Mem', 'Kingwood Park', 'New Caney', 'Porter', 'PA Memorial', 'Wisdom']
        var ElevenfourA = ['Kashmere', 'La Marque', 'Scarborough', 'Sweeny', 'Washington',  'Wharton'] 
        var TwelvefourA = ['Furr', 'Navasota', 'North Forest', 'Stafford',  'Wheatley', 'Worthing', 'Yates'] 
        var TwelvefourAtwo = ['Bellville', 'Giddings', 'La Grange', 'Smithville',  'Caldwell', 'Royal']
        var seventeensixA = ['Cy Fair','Cypress Falls', 'Cypress Creek', 'Mem Mustangs']
        var ElevenFourAR3DOne = ['Hargrave','Lumberton', 'Lit Cyp Mauriceville', 'Bridge City']
        var TwelveFiveAR3DTwo = ['Barbers Hill','Port Neches Groves', 'Crosby', 'Nederland']
        var TenFiveAR3DOne = ['Shadow Creek','Friendswood', 'Hightower', 'Foster']
       
        if (sixA.includes(TeamName.trim())){
            var region = '18-6A-' + sport.trim()
        }
        else if (ElevenfiveA.includes(TeamName.trim())){
            var region = '11-5A-' + sport.trim()
        }
        else if (NinefiveA.includes(TeamName.trim())){
            var region = '9-5A-' + sport.trim()
        }
        else if (ElevenfourA.includes(TeamName.trim())){
            var region = '11-4A-' + sport.trim()
        }
        else if (TwelvefourA.includes(TeamName.trim())){
            var region = '12-4A-' + sport.trim()
        }
        else if (TwelvefourAtwo.includes(TeamName.trim())){           
            var region = '12-4A2-' + sport.trim()
        }
        else if (seventeensixA.includes(TeamName.trim())){           
             var region = '17-6AR3-' + sport.trim()
         }
         else if (ElevenFourAR3DOne.includes(TeamName.trim())){           
            var region = '11-4AR3D1-' + sport.trim()
        }
        else if (TwelveFiveAR3DTwo.includes(TeamName.trim())){           
            var region = '12-5AR3D2-' + sport.trim()
        }
        else if (TenFiveAR3DOne.includes(TeamName.trim())){           
            var region = '10-5AR3D1-' + sport.trim()
        }
        else {
            var region = '-' + sport.trim()
        }
        
        for (var m=0; m<this.state.StandingResult.length; m++) {
            //console.log(TeamName)
            //console.log(region.trim())
            console.log(this.state.StandingResult[m].distict)
            if (this.state.StandingResult[m].distict===region.trim()) {

            
       
                for (var i=0; i< this.state.StandingResult[m].standingData.length;i++) {
                    //console.log(i)
                    //console.log(this.state.StandingResult[m].standingData[i].schoolName)
                    
                            if (this.state.StandingResult[m].standingData[i].schoolName === TeamName.trim()) {
                                return ( this.state.StandingResult[m].standingData[i].stats[2] +'-'+ this.state.StandingResult[m].standingData[i].stats[3] + ' (' +
                                this.state.StandingResult[m].standingData[i].stats[0] +'-'+ this.state.StandingResult[m].standingData[i].stats[1] + ')'  )
                            }
                    
                }
            }
        }
       
    }

    splitTeamArray = (data) => {
        //var data = [{"sport":"boy-Soccer"},{"sport":"girl-Soccer"},{"sport":"woman-Soccer"},{"sport":"boy-Soccer"}]
        
        data.sort(function(a,b){return ('' + a.sport).localeCompare(b.sport)});      
        var sportTeam = [];
        for (var i = 0; i < data.length; i++) { 
            if (data.length===1){
                sportTeam = [[data[i]]]
            }
            else if (i==0){
                var teamArray= [data[i]]
            }
            else {
                if (data[i-1]["sport"] === data[i]["sport"]){
                teamArray.push(data[i])
                }
                else {
                sportTeam.push(teamArray)
                
                teamArray= [data[i]]
                }
                if (data.length===i+1) {
                sportTeam.push(teamArray)
                }
            }
        }
        
        return sportTeam
    }    

    ScoreView = () => {        
           
        if (this.state.scoresData.length===0  ) {
            return (
                <View style={{backgroundColor: 'white', borderRadius:5, paddingTop:20, marginTop:20, height:Dimensions.get('window').height}}/> 
            )
        }
        else {
            //console.log(this.state.scoresData)
            return (  
                <View style={{flex:1}}>  
                {     
                this.state.scoresData.map(( item, key,) =>
                    (
                        <View style={{backgroundColor: 'white', borderRadius:5, paddingTop:20, marginTop:20,}}>    
                            <Text style={{color: 'black',  fontWeight:'bold', fontSize:16, paddingLeft:10, letterSpacing:2,}}>{moment(item[0].gameTime).format('dddd').toUpperCase() + ' - ' + moment(item[0].gameTime).format('MMMM Do')}</Text>
                            {
                                 this.splitTeamArray(item).map((item, key, arr) =>
                                 (
                                     <View style={{marginBottom:10}}>
                                        <Text style={{color: '#474645',fontWeight:'bold',  fontfamily:'Helvetica' ,  marginBottom:10, marginTop:10, fontSize:15, paddingLeft:20, letterSpacing:1.5}}>{item[0].sport}</Text>
                                        <View style={{borderBottomColor: '#D0D3D5', borderBottomWidth: 1, }}/> 
                                        {
                                                   item.map(( item, key, arr ) =>                
                                                   ( 
                                                       <View style={{ paddingLeft:15,paddingRight:15, marginBottom:0}}>
                                                           <View style={{flexDirection:'row', flex:1, marginTop:10}}>    
                                                               <View style={{flex:2.5, }}>
                                                                   <View style={{flexDirection:'row', flex:1, alignItems:'flex-end'}}>  
                                                                       <Image style={{width: 20, height: 15,  backgroundColor:'white', }}
                                                                           source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + item.homeTeam.split('-')[0].trim() + ' - ' + item.homeTeam.split('-')[1].trim() + '/logo'}} /> 
                                                                        <View style={{flex:1, flexDirection:'row'}} >                                                      
                                                                            <Text style = {{ fontWeight:'520', marginLeft: 10, fontfamily: 'Helvetica' , color:Number(item.homeTeamScore)>Number(item.awayTeamScore) ? 'black':'#787A7C',  fontSize:16}}>{item.homeTeam.split('-')[0]}</Text>
                                                                            <Text style = {{ marginTop:3,fontWeight:'520', marginLeft: 7, fontfamily: 'Helvetica' , color:Number(item.homeTeamScore)>Number(item.awayTeamScore) ? 'black':'#787A7C',  fontSize:11}}>{this.getTheTeamStanding(item.homeTeam.split('-')[0], item.homeTeam.split('-')[2]) }</Text>
                                                                        </View>
                                                                        <Text style = {{ fontWeight:'520', fontfamily: 'Helvetica' , color:Number(item.homeTeamScore)>Number(item.awayTeamScore) ? 'black':'#787A7C', marginLeft: 10,  fontSize:16,  textAlign:'right',}}>{item.homeTeamScore}</Text>
                                                                        {  Number(item.homeTeamScore)>Number(item.awayTeamScore) ? 
                                                                            <FaCaretLeft size={20} color='black' style={{ marginLeft:15, }}/> 
                                                                            :
                                                                            <View style={{ marginLeft:35, alignItems:'center', }}></View>
                                                                        }
                                                                   </View>
                                                                   <View style={{flexDirection:'row', alignContent:'center',flex:1, marginTop:3, }}> 
                                                                       <Image style={{width: 20, height: 15,  backgroundColor:'white', marginTop:0 }}
                                                                           source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + item.awayTeam.split('-')[0].trim() + ' - ' + item.awayTeam.split('-')[1].trim()  +'/logo'}} />                                                              
                                                                       <View style={{flex:1, flexDirection:'row',}} >  
                                                                            <Text style = {{ fontWeight:'520',marginLeft: 10, fontfamily: 'Helvetica' , color:Number(item.awayTeamScore)>Number(item.homeTeamScore) ? 'black':'#787A7C', fontSize:16, }}>{item.awayTeam.split('-')[0]}</Text>
                                                                            <Text style = {{ marginTop:3,fontWeight:'520', marginLeft: 7, fontfamily: 'Helvetica' , color:Number(item.awayTeamScore)>Number(item.homeTeamScore) ? 'black':'#787A7C',  fontSize:11}}>{this.getTheTeamStanding(item.awayTeam.split('-')[0], item.awayTeam.split('-')[2]) }</Text>
                                                                        </View>
                                                                       <Text style = {{fontWeight:'520', fontfamily: 'Helvetica' , color:Number(item.awayTeamScore)>Number(item.homeTeamScore) ? 'black':'#787A7C', marginLeft: 10, fontSize:16,  textAlign:'right',}}>{item.awayTeamScore}</Text>
                                                                       {  Number(item.awayTeamScore)>Number(item.homeTeamScore) ? 
                                                                             <FaCaretLeft size={20} color='black' style={{ marginLeft:15,}}/>  
                                                                             :  
                                                                            <View style={{ marginLeft:35, alignItems:'center', }}></View>
                                                                       }
                                                                   </View>
                                                               </View>
                                                               
                                                               {/*
                                                               Number(item.homeTeamScore)>Number(item.awayTeamScore) ? 
                                                                   
                                                                     
                                                                       <FaCaretLeft size={20} color='black' style={{paddingTop:7,  marginLeft:15, }}/>                                                    
                                                                  
                                                               : 
                                                               Number(item.awayTeamScore)>Number(item.homeTeamScore) ? 
                                                                   
                                                                       <FaCaretLeft size={20} color='black' style={{paddingTop:28, marginLeft:15,}}/>                                                    
                                                                  
                                                               :
                                                               <View style={{ marginLeft:15, alignItems:'center', }}></View>
                                                               */}
                                                               
                                                               <View style={{ borderLeftWidth: 1, borderLeftColor: '#D2D4D5', marginLeft:-6}}/>
                                                               
                                                               <View style={{flex:1,}}>
                                                                   <Text style = {{fontWeight:'520', color:'black', marginTop: 10,  flex: 1, marginRight:15,  fontSize:16, textAlign:'center',}}>{item.gameStatus ? item.gameStatus : moment(item.time, 'HH.mm').format('h:mm a')}</Text> 
                                                                   <Text style = {{fontWeight:'440', color:'#787A7C', marginTop: 10,  flex: 1, marginRight:15,  fontSize:14, textAlign:'center',}}>@ {item.venue}</Text> 
                                                               </View>
                                                              
                                                              
                                                           </View> 
                                                      
                                                           {  
                                                               arr.length-1 === key ? null : 
                                                                <View style={{borderBottomColor: '#D0D3D5', borderBottomWidth: 1, paddingTop:10 }}/> 
                                                               
                                                           }
                                                           
                                                        </View>
                                                        
                                                   )
                                               )
                                        } 
                                        {
                                            arr.length-1 === key ? null :
                                          <View style={{borderBottomColor: '#D0D3D5', borderBottomWidth: 1, paddingTop:10 }}/> 
                                        }
                                  </View>   
                                 ))
                                             
                            
                                
                            }
                            
                        </View>
                        
                    ) 
                    
                ) 
                }
                </View>              
            )
            
        }
    } 
//height: Dimensions.get('window').height,
    render() { 
       
        return (
            <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                apiKey='265ecd4214dc923fea7ba20a854373ff'           
                >
                <LogOnMount eventType="ScoresPage" />
                
                <View style={{  backgroundColor:'#D0D3D5',   }} >
              
                    
                    <View style={{marginTop:10,paddingBottom:100, backgroundColor:'white',width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',}}>
                    
                         
                    
                            <View style={{flex:1, flexDirection:'row', paddingTop:0, backgroundColor:'black', paddingBottom:0,}}>  
                                <TouchableOpacity style={{flex:1, backgroundColor:this.state.pageNo ===0 ? 'white':'black', paddingBottom:10, paddingTop:10}} onPress={() => {this.setState({pageNo: 0, stopcode:true}) ;this.ScoreData(0)}}>                             
                                    <Text style={{ color: this.state.pageNo ===0 ? 'black' : 'white',  textAlign:'center',fontSize:14, fontWeight:this.state.pageNo ===0 ? '600' : '', }} >LAST WEEK</Text>                              
                                    
                                </TouchableOpacity>
                                <TouchableOpacity  style={{flex:1, backgroundColor:this.state.pageNo ===1 ? 'white':'black', paddingBottom:10, paddingTop:10}} onPress={() => {this.setState({pageNo: 1, stopcode:true});this.ScoreData(1) }}>
                                    <Text style={{color:this.state.pageNo ===1 ? 'black' : 'white',textAlign:'center',fontSize:14, fontWeight:this.state.pageNo ===1 ? '600' : '', }}>THIS WEEK</Text>      
                                 
                                </TouchableOpacity>
                                <TouchableOpacity  style={{flex:1, backgroundColor:this.state.pageNo ===2 ? 'white':'black', paddingBottom:10, paddingTop:10}} onPress={() => {this.setState({pageNo: 2, stopcode: true});this.ScoreData(2) }}>
                                    <Text style={{color:this.state.pageNo ===2 ? 'black' : 'white', textAlign:'center', fontSize:14, fontWeight:this.state.pageNo ===2 ? '600' : '', }}>NEXT WEEK</Text>
                                   
                                </TouchableOpacity>
                            </View>

                            
                        <ScrollView>
                        {              
                            this.ScoreView()
                        }
                        </ScrollView>
                    </View>
                    
                    
                </View>
                
            </AmplitudeProvider>
        )
    } 
}

const mapStateToProps = state => {
    return { 
        viewingPage: state.viewingPage,
        codeInProgressReducer: state.codeInProgressReducer 
    }  
}

export default connect(mapStateToProps, actions)(Score);
  

