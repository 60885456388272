import React , { Component } from 'react';
import { Platform, StyleSheet, View, Image, ImageBackground, ScrollView, Text, TouchableOpacity} from 'react-native';
import { Dimensions, Animated } from 'react-native';
//import moment from "moment";
import * as actions from './actions';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import PlayerPage from './PlayerPage';
import LinesEllipsis from 'react-lines-ellipsis'
import { MdPlayArrow } from 'react-icons/md';



const { width, height } = Dimensions.get('window');
const widthScale = width / 320
const moment = require('moment-timezone');
//var momentmp = require('moment-timezone');

 class TeamOverview extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            next_game_element : '',
            props_element : '',
            gameDate : ''
        };
    } 

    updateBackgroundColor = (homeTeamName, homeTeamScore, awayTeamScore) => {        
        if (homeTeamScore != null && awayTeamScore!= '-') {   
            if (homeTeamName.includes(this.props.currentTeam)) {TeamA = homeTeamScore; TeamB = awayTeamScore}  
            else {var TeamB = homeTeamScore; var TeamA = awayTeamScore}         
            if (Number(TeamA)> Number(TeamB )){return 'green'}
            else if (Number(TeamA)< Number(TeamB)) {return 'red'}
            else  { return '#8D9193' }
        }
        else { 
            return '#8D9193'
        }       
    }

    componentDidMount() {
        console.log(window.location.href)
        console.log(window.location.href.split("/"))
    
       
      }
    
      componentWillUnmount() {
         
      }

  
    
    
    checkforRosterData = (rosterinfo) => {
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')
        console.log(this.props)
        if (rosterinfo.length===0 ) {
            return (
               <Text></Text> 
            )
        }
       else {
            return (         
                <ScrollView horizontal={true} style={{paddingTop:10,}}>
                {                     
                        rosterinfo.players.map(( item, key ) =>
                            (    
                                <Link to={`${this.props.routeProps.match.url}/roster/${item.split('[<>]')[1]}`} style={{ textDecoration: 'none' }}>                            
                                    <TouchableOpacity style={{width: 140, height: '100%', marginEnd:20}}
                                        onPress={()=> {                                       
                                            var arr = [];      
                                            for (var indPlayer in rosterinfo.players){arr.push(rosterinfo.players[indPlayer].split('[<>]')[0].trim());}                                     
                                            this.props.UpdateHomePage(0); 
                                            this.props.UpdatePlayerIndex(key); 
                                            this.props.updatePlayerDetailInfo(arr);
                                            

                                    }}>
                                        <Text numberOfLines={1} style = {{fontWeight:500,fontfamily: 'Helvetica' ,color: 'black',alignSelf: 'center', marginBottom:10, fontSize: 15}}>
                                        {//item.slice(item.split(' ',7).join('').length+7) 
                                            item.slice(item.split(' ',7).join('').length+7,item.split('[<>]',2).join('').length+4) 
                                        }
                                        </Text>
                                        <ImageBackground style={{ width: 90, height: 90, borderRadius: 60,alignSelf: 'center',}} source={{uri: 'https://www.integraconference.info/wp-content/uploads/2018/03/placeholder-face-big.png'}}>
                                            <Image
                                                style={{width: 120, height: 120, borderRadius: 60,alignSelf: 'center', }}
                                                source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + headerTitle.split('-')[0] + ' - ' + headerTitle.split('-')[1].split(" ")[0] + '/' 
                                                + headerTitle.split('-')[1]  + '/roster/' + item.slice(item.split(' ',7).join('').length+7,item.split('[<>]',2).join('').length+4), cache: 'default' }}>
                                        
                                            </Image>
                                        </ImageBackground>
                                        <Text style = {{fontWeight:500, fontfamily: 'Helvetica' ,color: 'black',alignSelf: 'center', marginTop:10, fontSize: 14}}>{item.split(' ')[5] + '/'+ item.split(' ')[2] }</Text>
                                    
                                    </TouchableOpacity>
                                </Link>
                            )
                        )                               
                
                }
                </ScrollView>                
            )
        }
    }

   
  

    checkforNewData = (newInfo) => {    

       
        if (newInfo.length===0 ) {
            return (
               <Text></Text> 
            )
        }
        else {
            return (         
                <ScrollView horizontal={true} style={{paddingTop:10,}}>
                {                  
                    newInfo.map(( item, key ) =>                
                        (   
                            <Link to={`${this.props.routeProps.match.url}/news/${item.topic}/`} style={{ textDecoration: 'none' }}>                              
                                <TouchableOpacity style={{backgroundColor: 'white',width: 250, height: '95%', marginEnd:20, borderRadius: 10,
                                    marginLeft:5, shadowRadius: 2, elevation: 4,
                                    shadowColor: '#000', shadowOffset: { width: 0.5, height: 0.5}, shadowOpacity: 0.5,}} 
                                    onPress={()=> { this.props.UpdateHomePage(0); this.props.UpdateNewPageIndex(key)}}>                        
                                
                                    <ImageBackground style={{justifyContent:'center',borderTopRightRadius:10, borderTopLeftRadius:10, width: 250, height: 140, backgroundColor: '#8D9193', overflow:'hidden'}} 
                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + item.topic.trim().replace("?",""),cache: 'reload'}}>
                                         {
                                                        item.fileType != 'video' ?
                                                        null
                                                        :
                                                        <View style={{backgroundColor:'rgba(255,255,255, 0.7)',alignSelf:'center',borderRadius:15, }}>
                                                            <MdPlayArrow size={30} color='black' style={{alignSelf:'center'}} />  
                                                        </View>
                                                        
                                            }
                                    </ImageBackground>
                                    <View style={{padding:5, marginTop:7,   }}>
                                        <Text numberOfLines={2} style = {{ fontfamily:  'Helvetica' , color: 'black',fontSize: 15, fontWeight:500}}>
                                            <LinesEllipsis
                                                text={item.topic}
                                                maxLine='2'
                                                ellipsis='...'
                                                trimRight
                                                basedOn='letters'
                                            />
                                        </Text>
                                        <Text style = {{color: '#787A7C', fontWeight:450, marginTop:20, fontSize: 11,}}>{item.date} </Text>
                                    </View>
                                    
                                </TouchableOpacity>
                            </Link>
                        )
                   ) 
                }                  
                </ScrollView>                
            )
        }
    }  
    
    checkLast5Game = (homeTeamName, TeamAScore, TeamBScore) => {
        console.log(homeTeamName + '-' + TeamAScore + '-' + TeamBScore)
        if (homeTeamName.includes(this.props.currentTeam)) {return (' ' + TeamAScore + ' - ' + TeamBScore + ' ')}
        else {return (' ' + TeamBScore + ' - ' + TeamAScore + ' ')}
    }

    checkforFixturesData = () => {        
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')        
        if ( this.props.fixtureData.length===0 ) {
            return ( <Text></Text> )
        }
        else {          
            return (                     
                <View style={{flex:1, flexDirection:'row', width:'100%', justifyContent: 'space-between', paddingTop: 20, alignSelf: 'center',}}>
                    <View>
                        <Text style={{overflow: "hidden", borderRadius: 5,color:'white', fontSize: 15, backgroundColor:this.props.props_element < 5 ? null: 
                            this.updateBackgroundColor(this.props.fixtureData[this.props.props_element-5].homeTeam,this.props.fixtureData[this.props.props_element-5].homeTeamScore, this.props.fixtureData[this.props.props_element-5].awayTeamScore), 
                            alignSelf: 'center', fontWeight:500}}>{this.props.props_element < 5 || !this.props.fixtureData[this.props.props_element-5].homeTeamScore ? '-'  : this.checkLast5Game( this.props.fixtureData[this.props.props_element-5].homeTeam, 
                            this.props.fixtureData[this.props.props_element-5].homeTeamScore, this.props.fixtureData[this.props.props_element-5].awayTeamScore )}
                        </Text>
                        {this.props.props_element < 5 ? null :
                             <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginTop:15}}
                                    source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element-5].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                    this.props.fixtureData[this.props.props_element-5].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element-5].homeTeam.split('-')[1].trim() + '/logo' : 
                                    this.props.fixtureData[this.props.props_element-5].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element-5].awayTeam.split('-')[1].trim() + '/logo')}} 
                                />
                        }
                    </View>
                    <View>
                        <Text style={{overflow: "hidden", borderRadius: 5,color:'white', fontSize: 15, backgroundColor: this.props.props_element < 4 ? null: 
                            this.updateBackgroundColor(this.props.fixtureData[this.props.props_element-4].homeTeam, this.props.fixtureData[this.props.props_element-4].homeTeamScore, this.props.fixtureData[this.props.props_element-4].awayTeamScore), 
                            alignSelf: 'center', fontWeight:500 }}>{this.props.props_element < 4 || !this.props.fixtureData[this.props.props_element-4].homeTeamScore ? '-'  :this.checkLast5Game( this.props.fixtureData[this.props.props_element-4].homeTeam, 
                            this.props.fixtureData[this.props.props_element-4].homeTeamScore, this.props.fixtureData[this.props.props_element-4].awayTeamScore)}
                        </Text>
                        {this.props.props_element < 4 ? null :
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginTop:15}}
                                    source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element-4].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                    this.props.fixtureData[this.props.props_element-4].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element-4].homeTeam.split('-')[1].trim() + '/logo' : 
                                    this.props.fixtureData[this.props.props_element-4].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element-4].awayTeam.split('-')[1].trim() + '/logo')}} 
                                />
                        }
                    </View>
                    <View>
                        <Text style={{overflow: "hidden", borderRadius: 5,color:'white', fontSize: 15, backgroundColor: this.props.props_element < 3 ? null: 
                            this.updateBackgroundColor(this.props.fixtureData[this.props.props_element-3].homeTeam,this.props.fixtureData[this.props.props_element-3].homeTeamScore, this.props.fixtureData[this.props.props_element-3].awayTeamScore), 
                            alignSelf: 'center', fontWeight:500 }}>{this.props.props_element < 3 || !this.props.fixtureData[this.props.props_element-3].homeTeamScore ? '-'  :this.checkLast5Game( this.props.fixtureData[this.props.props_element-3].homeTeam, 
                            this.props.fixtureData[this.props.props_element-3].homeTeamScore, this.props.fixtureData[this.props.props_element-3].awayTeamScore)}
                        </Text>
                        {this.props.props_element < 3 ? null :
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginTop:15}}
                                    source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element-3].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                    this.props.fixtureData[this.props.props_element-3].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element-3].homeTeam.split('-')[1].trim() + '/logo' : 
                                    this.props.fixtureData[this.props.props_element-3].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element-3].awayTeam.split('-')[1].trim() + '/logo')}} 
                                />
                        }
                    </View>
                    <View>
                        <Text style={{overflow: "hidden", borderRadius: 5,color:'white', fontSize: 15, backgroundColor: this.props.props_element < 2 ? null: 
                            this.updateBackgroundColor(this.props.fixtureData[this.props.props_element-2].homeTeam, this.props.fixtureData[this.props.props_element-2].homeTeamScore, this.props.fixtureData[this.props.props_element-2].awayTeamScore), 
                            alignSelf: 'center', fontWeight:500 }}>{this.props.props_element < 2 || !this.props.fixtureData[this.props.props_element-2].homeTeamScore ? '-'  :this.checkLast5Game( this.props.fixtureData[this.props.props_element-2].homeTeam, 
                                this.props.fixtureData[this.props.props_element-2].homeTeamScore, this.props.fixtureData[this.props.props_element-2].awayTeamScore)}
                        </Text>
                        {this.props.props_element < 2 ? null :
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginTop:15}}
                                    source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element-2].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                    this.props.fixtureData[this.props.props_element-2].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element-2].homeTeam.split('-')[1].trim() + '/logo' : 
                                    this.props.fixtureData[this.props.props_element-2].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element-2].awayTeam.split('-')[1].trim() + '/logo')}} 
                                />
                            }
                    </View>
                    <View>  
                        {console.log(this.props.props_element)}  
                        <Text style={{ overflow: "hidden", borderRadius: 5, color:'white', fontSize: 15, backgroundColor:this.props.props_element< 1 ? null:
                            this.updateBackgroundColor(this.props.fixtureData[this.props.props_element-1].homeTeam, this.props.fixtureData[this.props.props_element-1].homeTeamScore, this.props.fixtureData[this.props.props_element-1].awayTeamScore), 
                            alignSelf: 'center', fontWeight:500}}>{this.props.props_element < 1 || !this.props.fixtureData[this.props.props_element-1].homeTeamScore ? '-' : this.checkLast5Game( this.props.fixtureData[this.props.props_element-1].homeTeam, 
                            this.props.fixtureData[this.props.props_element-1].homeTeamScore, this.props.fixtureData[this.props.props_element-1].awayTeamScore)}
                        </Text>  
                        {this.props.props_element < 1 ? null :
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginTop:15}}
                                source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element-1].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                this.props.fixtureData[this.props.props_element-1].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element-1].homeTeam.split('-')[1].trim() + '/logo' : 
                                this.props.fixtureData[this.props.props_element-1].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element-1].awayTeam.split('-')[1].trim() + '/logo')}} 
                            />
                        }
                    </View>                
                </View>              
            )
        }
    } 

    checkforNextGame = () => {        
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')
        console.log(this.props.props_element)
        if (this.props.fixtureData.length===0 || this.props.fixtureData.length <= this.props.props_element || this.props.props_element===-1) {
            return (
                <View style={{flex:1, flexDirection:'row',paddingTop: 20, alignSelf: 'center' }}>
                    
                <Text style={{color:'black', fontSize: 15, paddingRight: 10, fontWeight:500,}}>-</Text>
                <Text style={{color:'black', fontSize: 15, paddingLeft:5, paddingRight:5, fontWeight:500}}>-</Text>
                <Text style={{color: 'black', fontSize: 15, paddingLeft:10, fontWeight:500}}>-</Text>
            </View>
            )
        }
        else {
           
            return (   
            <View >   
                <View style={{flex:1, flexDirection:'row',paddingTop: 15, alignSelf: 'center' }}>
                        
                        <Text style={{fontfamily:  'Helvetica', color:'black', fontSize: 16, paddingRight: 10, fontWeight:520}}>{moment((this.props.fixtureData[this.props.props_element].gameTime)).format("ddd, MMM D")}</Text>
                        <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', }}
                                source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' + (this.props.fixtureData[this.props.props_element].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                                this.props.fixtureData[this.props.props_element].homeTeam.split('-')[0].trim()  + ' - ' + this.props.fixtureData[this.props.props_element].homeTeam.split('-')[1].trim() + '/logo' : 
                                this.props.fixtureData[this.props.props_element].awayTeam.split('-')[0].trim() + ' - ' + this.props.fixtureData[this.props.props_element].awayTeam.split('-')[1].trim() + '/logo')}} 
                            />
                        <Text style={{fontfamily: 'Helvetica' , color:'black', fontSize: 16, paddingLeft:5, paddingRight:5, fontWeight:520}}>
                            {this.props.fixtureData[this.props.props_element].awayTeam.includes(this.props.currentTeamSel.split("-")[0]) ? 
                            '@'+this.props.fixtureData[this.props.props_element].homeTeam.split("-")[0]: this.props.fixtureData[this.props.props_element].awayTeam.split("-")[0]}</Text>
                            {console.log(this.props.fixtureData[this.props.props_element].gameTime + ' ' + this.props.fixtureData[this.props.props_element].time)}
                        <Text style={{fontfamily: 'Helvetica' , color: 'black', fontSize: 16, paddingLeft:10, fontWeight:520}}>{moment(this.props.fixtureData[this.props.props_element].gameTime + ' ' + this.props.fixtureData[this.props.props_element].time).format("LT")}</Text>
                </View> 
                <Text style={{marginTop:15, paddingLeft:5, fontSize: 15, fontfamily: 'Helvetica',fontWeight:520, color:'#787A7C'}}>Stadium: {this.props.fixtureData[this.props.props_element].venue}</Text>  
            </View>                      
            )
        }
    } 

    teamOverViewPage = () => {
        return (
            <View style={{paddingBottom:20,backgroundColor:'white', marginTop:-21, paddingTop:20}}>
            
                <View style={{marginLeft:10, marginRight:10, marginBottom: 25, backgroundColor:'white', marginTop:1, height: 120,
                        borderRadius:10, padding: 15,
                        shadowColor: '#000',shadowOffset: { width: 0.5, height: 0.5},shadowOpacity: 0.5,
                        shadowRadius: 2, elevation: 4,  
                        }}> 
                    <Text style={{letterSpacing:2, fontfamily:  'Helvetica' , color:'black', fontSize: 16,fontWeight:635 }}>Next game</Text>
                        {
                           this.checkforNextGame() 
                        }
                </View>
    
                <View style={{marginLeft:10, marginRight:10, marginBottom: 25, backgroundColor:'white', 
                    height: 150, borderRadius:10, padding: 15,fontWeight:650, fontfamily:'Helvetica',
                    shadowColor: '#000',shadowOffset: { width: 0.5, height: 0.5},shadowOpacity: 0.5,
                                        shadowRadius: 2, elevation: 4,  
                    }}> 
                    <Text style={{letterSpacing:1.4, fontfamily: 'Helvetica' , color:'black', fontSize: 16,fontWeight:635 }}>Last 5 games</Text>
                    
                    {
                        this.checkforFixturesData()
                    }
                </View>
    
                <View style={{marginLeft:10, marginRight:10, marginBottom: 25, backgroundColor:'white', 
                    height: 220, borderRadius:10, padding: 15,fontWeight:700, fontfamily:'Helvetica',
                    shadowColor: '#000',shadowOffset: { width: 0.5, height: 0.5},shadowOpacity: 0.5,
                                        shadowRadius: 2, elevation: 4,  
                    }}> 
                    <Text style={{letterSpacing:2, fontfamily: 'Helvetica' , color:'black',  fontSize: 16, fontWeight:635}}>Roster</Text>
                    {
                        this.checkforRosterData(this.props.rosterData) 
                    }
                </View>
    
                <View style={{marginLeft:10, marginRight:10, backgroundColor:'white', 
                    height: 300, borderRadius:10, padding: 15,
                    shadowColor: '#000',shadowOffset: { width: 0.5, height: 0.5},shadowOpacity: 0.5,
                                        shadowRadius: 2, elevation: 4,  
                    }}> 
                    <Text style={{letterSpacing:2, fontfamily: 'Helvetica' , color:'black', fontSize: 16, fontWeight:635 }}>News</Text>
                    {
                        this.checkforNewData(this.props.teamNewData) 
                    }
                </View>
            </View>
        );    
    }
  
        /*
        <Route  path={`${this.props.match}:id`}
          render={(props) => console.log('making progress')}
             />     
        <Route
            exact
            path={this.props.match.path}
            render={() => <h3>{this.teamOverViewPage()}</h3>}
        />    
            */
    render() { 
        const headerTitle = this.props.currentTeamSel.split('_').join(' ')  
        
    return (
        
        <h3>{this.teamOverViewPage()}</h3>
        
    );
    
    }   
}

const mapStateToProps = state => {
    return { 
        currentTeamSel: state.currentTeamSel,
        
    }
}
export default connect(mapStateToProps, actions)(TeamOverview)