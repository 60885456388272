import React, { Component } from 'react';
import {  Platform, StyleSheet,Text, View, Image, ImageBackground, ScrollView, FlatList, Dimensions } from 'react-native';
import * as actions from './actions';
import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';
import { connect } from 'react-redux';
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";

const { width, height } = Dimensions.get('window');
const widthScale = width / 320

class Table extends Component {

    constructor() {
        super()
        this.state = {
            
            standingData: []
        }
    }

    StandingData = (regionName) => {
        console.log(regionName)
        const standing = `
          query standing ($regionName: String!) {
            standing (regionName: $regionName)  {
              items{regionName points stats schoolName}
            }
          }`;
        API.graphql(graphqlOperation(standing, { regionName: regionName })).then(response => {
           
           const a = response.data.standing.items.sort(function(a,b){return Number(b.points) - Number(a.points)});
            this.setState({standingData: response.data.standing.items.sort(function(a,b){return Number(b.points) - Number(a.points)})});
        }).catch(e => {console.log(e);});
      }
    
    componentWillMount() {
        console.log(this.props.standingProps);
        this.StandingData(this.props.standingProps);
    }
    
    /*
    componentWillUnmount() {
        this.subs.forEach(sub => sub.remove());
    }
    */
    /*
    componentDidMount() {
        this.subs = [
            this.props.navigation.addListener('didFocus', () => this.leaderboard()),
        ];
    }   
    */
    _renderItem = ({ item, index }) => (
       
        <View style={{backgroundColor: 'white',flexDirection: 'row', margin: 10, justifyContent: 'center', textAlign: 'center',flex:1}} >
           
            <Text style={{ fontWeight:500, alignSelf: 'center', justifyContent: 'center', textAlign: 'center', fontSize: 15, color: 'black', flex:0.7 }}>{index + 1}</Text>
            <View style={{ alignSelf: 'center', flex: 0.7, backgroundColor:'white' }}>
            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center'}}
                                                            source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' +  item.schoolName.split('-')[0] + ' - ' + item.regionName.split('-')[1] + '/logo'}} 
                                                 
                                                 />
            </View>
            <Text style={{ fontWeight:500, fontfamily: 'Helvetica' , alignSelf: 'center', fontSize: 15,  color: 'black', flex:2.7 }}>{item.schoolName}</Text>
            <Text style={{ fontWeight:500, alignSelf: 'center', textAlign: 'center', fontSize: 15,  color: 'black', flex: 0.5, }}>{item.stats[0]}</Text>
            <Text style={{ fontWeight:500, alignSelf: 'center', textAlign: 'center', fontSize: 15,  color: 'black', flex: 0.5,}}>{item.stats[1]}</Text>
            <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.7,}}></Text>
            <Text style={{ fontWeight:500, alignSelf: 'center', textAlign: 'center', fontSize: 15,  color: 'black', flex: 0.5, }}>{item.stats[2]}</Text>
            <Text style={{ fontWeight:500, alignSelf: 'center', textAlign: 'center', fontSize: 15,  color: 'black', flex: 0.5,}}>{item.stats[3]}</Text>
          
        </View>    
    );

    _renderItemNothing = ({ item, index }) => (
        <View style={{ flexDirection: 'row', marginTop: 7, marginBottom: 7, justifyContent: 'center', textAlign: 'center', }} >
        </View>
    );

    renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "black",                    
                }}
            />
        );
    };

    renderHeader = () => {
        return (
            <View> 
                <View style={{flexDirection:'row'}}>
                     <Text style={{ fontWeight:630,alignSelf: 'center',textAlign: 'end', fontSize: 17,  color: 'black', flex:6.8 }}>District</Text>
                     <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.7,}}></Text>
                    <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 1.6, paddingLeft:20}}>All</Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10, justifyContent: 'center', textAlign: 'center',flex:1}} >
                    
                    <Text style={{ fontWeight:630, alignSelf: 'center', justifyContent: 'center', textAlign: 'center', fontSize: 17 , color: 'black', flex:0.7 }}>#</Text>
                    <View style={{ alignSelf: 'center', flex: 0.7, }}/>
                
                
                    <Text style={{ fontWeight:630,alignSelf: 'center',textAlign: 'center', fontSize: 17,  color: 'black', flex:2.7 }}>Team</Text>
                    <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.5, }}>W</Text>
                    <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.5,}}>L</Text>
                    <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.7,}}></Text>
                    <Text style={{ fontWeight:630,alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.5, }}>W</Text>
                    <Text style={{ fontWeight:630, alignSelf: 'center', textAlign: 'center', fontSize: 17,  color: 'black', flex: 0.5,}}>L</Text>
                   
                </View>
            </View>
        
        )

        
    }

    render() {       
        const list = this.props.leaderBoard;   
        let { height } = Dimensions.get("window");
        console.log(this.props.standingData.length === 0)
        return (
            <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                apiKey='265ecd4214dc923fea7ba20a854373ff'               
                >  
                <Amplitude
                    eventProperties={{                        
                    "School Name": this.props.currentTeam,  
                    }}
                    >                
                <LogOnMount eventType="StandingPage" />
                <View style={{ paddingTop: 10, backgroundColor:'white'}}>
                                
                    <FlatList                                 
                        data={this.state.standingData}
                        renderItem={this.state.standingData.length === 0 ? this._renderItemNothing : this._renderItem  }
                        ItemSeparatorComponent={this.renderSeparator}     
                        ListHeaderComponent={this.renderHeader}     
                    />
                </View>
                </Amplitude>
            </AmplitudeProvider>
        );
        
    }
}
const mapStateToProps = state => {
    return {
        leaderBoard: state.leaderBoard,
        count: state.countQuestion,
        standingData: state.standingData
    }
}
export default connect(mapStateToProps, actions)(Table);