/*
mutation addStats {
    addStats(
        date:"2019-19-10",
        game:"yatesVSmadision",
        homeTeamStats:"34 54 34",
      	awayTeamStats:"5 45 4"       
        ){
        date,
    		game
    }
}
*/
import * as React from 'react';
import { Text, TextInput, View, Dimensions, Button, TouchableOpacity, PanResponder, ImageBackground, ScrollView, Image } from 'react-native';
import LinesEllipsis from 'react-lines-ellipsis';
import { FaLock} from 'react-icons/fa';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { IoMdText} from 'react-icons/io';
import { MdPlayArrow } from 'react-icons/md';
import { connect } from 'react-redux';
import * as actions from './actions';
import Popup from "reactjs-popup";
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';

const { width, height } = Dimensions.get('screen')
class Developer extends React.Component {
   constructor(props) {
        super(props);        
        this.state = {            
            Imageratio:1,
            PageSizeWidth:Dimensions.get('window').width ,
           homeTeamPoints:0,
           awayTeamPoints:0,
           playerSelected:'',
           playerNoSelected:'',
           homeTeamPlayerName: '',
           awayTeamPlayerName: '',
           homeTeamPlayerNo: '',
           awayTeamPlayerNo: '',
           dashboard:true,
           editState:true,
           selectTeamNote:false
        }
        
    }

    componentDidMount() {
        this.checkForANyNameInput();
        window.addEventListener('resize', this.handleResize);
          
         
    }

    saveStatsOnline = () => {  
       
        const stats = `
        mutation addStats ($date:String! $game:String! $homeTeamStats:[String] $awayTeamStats:[String] $homeTeamScore:String $awayTeamScore:String $gameMatrix:[String])  {
            addStats(date:$date game:$game homeTeamStats:$homeTeamStats awayTeamStats:$awayTeamStats homeTeamScore:$homeTeamScore awayTeamScore:$awayTeamScore gameMatrix:$gameMatrix)
            {date game}
        }`;
        
            
            
            API.graphql(graphqlOperation(stats,
                 { date: new Date().toISOString().slice(0, 10),
                    game:'YatesVsMadison',
                    homeTeamStats:this.props.homeTeamStats, 
                    awayTeamStats:this.props.awayTeamStats,
                    homeTeamScore:this.state.homeTeamPoints,
                    awayTeamScore:this.state.awayTeamPoints,
                    gameMatrix:this.props.playMatrix
                })).then(response => { 
                console.log(response)
            }).catch(e => {console.log(e);});
       
    }
    removeLastPlay = () => {  
        if (this.props.playMatrix.length>0){
            var playType = this.props.playMatrix[this.props.playMatrix.length-1].split('#')[1].split(' ')[1]
            var playerNo = this.props.playMatrix[this.props.playMatrix.length-1].split('#')[1].split(' ')[0]
            var HomeorAwayTeam = this.props.playMatrix[this.props.playMatrix.length-1].split(' ')[0]
            this.props.updatePlayMatrix(this.props.playMatrix.slice(0, -1))
            if (HomeorAwayTeam === 'homeTeam'){
                for (var i in this.props.homeTeamStats)  {
                    if (playerNo===this.props.homeTeamStats[i][1])  {                
                        switch(playType) {
                            case 'freethrow':
                                this.setState({homeTeamPoints: this.state.homeTeamPoints-1, selectTeamNote:false})
                                this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]-1
                                this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)                
                            break;
                            case 'twopoints':
                                this.setState({homeTeamPoints: this.state.homeTeamPoints-2,  selectTeamNote:false})
                                this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]-2
                                this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                            
                            break;
                            case 'threepoints':
                                this.setState({homeTeamPoints: this.state.homeTeamPoints-3, selectTeamNote:false})
                                this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]-3
                                this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                
                            break;
                            case 'steal':
                                this.setState({ selectTeamNote:false})
                                this.props.homeTeamStats[i][5] = this.props.homeTeamStats[i][5]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                
                            break;
                            case 'turnover':
                                this.setState({ selectTeamNote:false})
                                this.props.homeTeamStats[i][7] = this.props.homeTeamStats[i][7]-1 //point, Assist, rebound, steal, block, turnover, miss 
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                
                            break;
                            case 'rebound':
                                this.setState({ selectTeamNote:false})
                                this.props.homeTeamStats[i][4] = this.props.homeTeamStats[i][4]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                            
                            break;
                            case 'block':
                                this.setState({  selectTeamNote:false})
                                this.props.homeTeamStats[i][6] = this.props.homeTeamStats[i][6]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                
                            break;
                            case 'miss':
                                this.setState({  selectTeamNote:false})
                                this.props.homeTeamStats[i][8] = this.props.homeTeamStats[i][8]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                            
                            break;
                            case 'assist':
                                this.setState({ selectTeamNote:false})
                                this.props.homeTeamStats[i][3] = this.props.homeTeamStats[i][3]-1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                
                            break;
                            
                            default:
                            break;
                        }
                    }
                } 
            }
        
            else {
                for (var i in this.props.awayTeamStats)  {
                    if (playerNo===this.props.awayTeamStats[i][1])  {                
                        switch(playType) {
                            case 'freethrow':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints-1, selectTeamNote:false})
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]-1
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)                
                            break;
                            case 'twopoints':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints-2,  selectTeamNote:false})                                
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]-2
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                            
                            break;
                            case 'threepoints':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints-3, selectTeamNote:false})
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]-3
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                
                            break;
                            case 'steal':
                                this.setState({ selectTeamNote:false})
                                this.props.awayTeamStats[i][5] = this.props.awayTeamStats[i][5]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                
                            break;
                            case 'turnover':
                                this.setState({ selectTeamNote:false})
                                this.props.awayTeamStats[i][7] = this.props.awayTeamStats[i][7]-1 //point, Assist, rebound, steal, block, turnover, miss 
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                
                            break;
                            case 'rebound':
                                this.setState({ selectTeamNote:false})
                                this.props.awayTeamStats[i][4] = this.props.awayTeamStats[i][4]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                            
                            break;
                            case 'block':
                                this.setState({  selectTeamNote:false})
                                this.props.awayTeamStats[i][6] = this.props.awayTeamStats[i][6]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                
                            break;
                            case 'miss':
                                this.setState({  selectTeamNote:false})
                                this.props.awayTeamStats[i][8] = this.props.awayTeamStats[i][8]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                            
                            break;
                            case 'assist':
                                this.setState({ selectTeamNote:false})
                                this.props.awayTeamStats[i][3] = this.props.awayTeamStats[i][3]-1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                
                            break;
                            
                            default:
                            break;
                        }
                    }
                } 
            }
        }
    }
    AddStats = (stats) => { 
        
        if (!(this.state.playerNoSelected==='')) {
            if (this.state.playerNoSelected.includes("home")) {
                for (var i in this.props.homeTeamStats)  {          
                    if (this.state.playerNoSelected===this.props.homeTeamStats[i][1]+'home')  {   
                        switch(stats) {
                            case 'freethrow':
                            this.setState({homeTeamPoints: this.state.homeTeamPoints+1, playerNoSelected:'', selectTeamNote:false})
                            this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]+1
                            this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]+1
                            this.props.updateHomeTeamStats(this.props.homeTeamStats)                            
                            this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' +  this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' freethrow'))
                            break;
                            case 'twopoints':
                                this.setState({homeTeamPoints: this.state.homeTeamPoints+2, playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]+2
                                this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' twopoints'))
                            break;
                            case 'threepoints':
                                this.setState({homeTeamPoints: this.state.homeTeamPoints+3, playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][2] = this.props.homeTeamStats[i][2]+3
                                this.props.homeTeamStats[i][9] = this.props.homeTeamStats[i][9]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' threepoints'))
                            break;
                            case 'steal':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][5] = this.props.homeTeamStats[i][5]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' steal'))
                            break;
                            case 'turnover':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][7] = this.props.homeTeamStats[i][7]+1 //point, Assist, rebound, steal, block, turnover, miss 
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' turnover'))
                            break;
                            case 'rebound':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][4] = this.props.homeTeamStats[i][4]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' rebound'))
                            break;
                            case 'block':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][6] = this.props.homeTeamStats[i][6]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' block'))
                            break;
                            case 'miss':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][8] = this.props.homeTeamStats[i][8]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' miss'))
                            break;
                            case 'assist':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.homeTeamStats[i][3] = this.props.homeTeamStats[i][3]+1
                                this.props.updateHomeTeamStats(this.props.homeTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('homeTeam ' + this.props.homeTeamStats[i][0] + ' #' + this.props.homeTeamStats[i][1] + ' assist'))
                            break;
                            
                            default:
                            break;
                        }
                        
                    }
                }
            }
            else {
                for (var i in this.props.awayTeamStats)  {          
                    if (this.state.playerNoSelected===this.props.awayTeamStats[i][1]+'away')  {   
                        switch(stats) {
                            case 'freethrow':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints+1, playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]+1
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' +  this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' freethrow'))
                            break;
                            case 'twopoints':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints+2, playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]+2
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' twopoints'))
                            break;
                            case 'threepoints':
                                this.setState({awayTeamPoints: this.state.awayTeamPoints+3, playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][2] = this.props.awayTeamStats[i][2]+3
                                this.props.awayTeamStats[i][9] = this.props.awayTeamStats[i][9]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' threepoints'))
                            break;
                            case 'steal':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][5] = this.props.awayTeamStats[i][5]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' steal'))
                            break;
                            case 'turnover':
                                this.setState({playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][7] = this.props.awayTeamStats[i][7]+1  //point, Assist, rebound, steal, block, turnover, miss 
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' turnover'))
                            break;
                            case 'rebound':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][4] = this.props.awayTeamStats[i][4]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' rebound'))
                            break;
                            case 'block':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][6] = this.props.awayTeamStats[i][6]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' block'))
                            break;
                            case 'miss':
                                this.setState({playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][8] = this.props.awayTeamStats[i][8]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' miss'))
                            break;
                            case 'assist':
                                this.setState({ playerNoSelected:'', selectTeamNote:false})
                                this.props.awayTeamStats[i][3] = this.props.awayTeamStats[i][3]+1
                                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                                this.props.updatePlayMatrix(this.props.playMatrix.concat('awayTeam ' + this.props.awayTeamStats[i][0] + ' #' + this.props.awayTeamStats[i][1] + ' assist'))
                            break;
                            
                            default:
                            break;
                        }
                        
                    
                    }
                }
            }
        }
        else {
            this.setState({selectTeamNote:true})
        }
        

    }

    AddPlayerInfoView = () => {
        return (
            <TouchableOpacity  style={{marginTop:15, width: 70, marginEnd:5, shadowRadius:3}}>
         
                <TouchableOpacity style={{margin:2, flex:1,justifyContent: "center",alignItems: "center"}}>
                    <Text style={{textAlignVertical: "center",textAlign: "center", color:'#6B6A69'}}>Add Player Name and Number</Text>
                </TouchableOpacity>
            
            </TouchableOpacity> 
        )
    }
  //point, Assist, rebound, steal, block, turnover, miss 
    homeTeamPlayerInfo = (item) => {
        
        return (
            
            <TouchableOpacity onPress= {() => this.setState({playerNoSelected:item[1]+'home'})} style={{marginTop:0, width: 80, marginEnd:5, shadowRadius: item[1]+'home'===this.state.playerNoSelected?3:0, }}>
                {item[2] !== 0 || item[8] !== 0 ?
                <Text numberOfLines={1} style = {{color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[2]} Points {item[9]}/{item[8]+item[9]}</Text>
                : null}
                {item[3] !== 0 ?
                <Text numberOfLines={1} style = {{fontWeight:500,fontfamily: 'Helvetica' ,color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', marginBottom:3, fontSize: 12}}>{item[3]} Assists</Text>
                : null}
                {item[4] !== 0 ?
                <Text numberOfLines={1} style = {{fontWeight:500,fontfamily: 'Helvetica' ,color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', marginBottom:3, fontSize: 12}}>{item[4]} Rebounds</Text>
                : null}
                {item[5] !== 0 || item[6] !== 0?
                <Text numberOfLines={1} style = {{color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[5] + item[6] } Steals/Blocks</Text>
                : null}
                {item[7] !== 0 ?
                <Text numberOfLines={1} style = {{color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[7]} Turnover</Text>
                : null}
                
                {                
                <ImageBackground style={{ width: 50, height: 50, borderRadius: 60,alignSelf: 'center', marginTop:3,}} source={{uri: 'https://www.integraconference.info/wp-content/uploads/2018/03/placeholder-face-big.png'}}>
                    <Image
                        style={{width: 120, height: 120, borderRadius: 60,alignSelf: 'center', }}
                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/'}}>
                    </Image>
                </ImageBackground>                
                }
                <Text numberOfLines={1} style = {{fontWeight:"500",fontFamily: 'Helvetica' ,color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', fontSize: 12, }}>{item[0]}</Text>
                <Text numberOfLines={1} style = {{fontWeight:"500",fontFamily: 'Helvetica' ,color: item[1]+'home'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', fontSize: 12, }}>#{item[1]}</Text>
            </TouchableOpacity> 

        )
    }

    awayTeamPlayerInfo = (item) => {
        console.log(item[2])
        return (
            <TouchableOpacity onPress= {() => this.setState({playerNoSelected:item[1]+'away'})} style={{marginTop:5, width: 80, marginEnd:5, shadowRadius: item[1]+'away'===this.state.playerNoSelected?3:0,   }}>
                 <Text numberOfLines={1} style = {{fontWeight:"500",fontFamily: 'Helvetica' ,color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', fontSize: 12, }}>#{item[1]}</Text> 
                <Text numberOfLines={1} style = {{fontWeight:"500",fontFamily: 'Helvetica' ,color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', fontSize: 12, }}>{item[0]}</Text>                               
                <ImageBackground style={{ width: 50, height: 50, borderRadius: 60,alignSelf: 'center', marginTop:3,}} source={{uri: 'https://www.integraconference.info/wp-content/uploads/2018/03/placeholder-face-big.png'}}>
                    <Image
                        style={{width: 120, height: 120, borderRadius: 60,alignSelf: 'center', }}
                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/'}}>
                    </Image>
                </ImageBackground>                
                {item[7] !== 0 ?
                <Text numberOfLines={1} style = {{color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[7]} Turn Over</Text>
                : null}
                {item[5] !== 0 || item[6] !== 0?
                <Text numberOfLines={1} style = {{fontWeight:500,fontfamily: 'Helvetica' ,color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center', marginBottom:3, fontSize: 12}}>{item[5] + item[6]} Steals/Blocks</Text>
                : null}
                {item[4] !== 0 ?
                <Text numberOfLines={1} style = {{fontWeight:500,fontfamily: 'Helvetica' ,color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0',alignSelf: 'center',  fontSize: 12}}>{item[4]} Rebounds</Text>
                : null}
                 {item[3] !== 0 ?
                <Text numberOfLines={1} style = {{color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[3]} Assist</Text>
                : null}
                {item[2] !== 0 || item[8] !== 0 ?
                <Text numberOfLines={1} style = {{color: item[1]+'away'===this.state.playerNoSelected?'black':'#B0B0B0', fontWeight:500,fontfamily: 'Helvetica' ,alignSelf: 'center', marginBottom:3, fontSize: 12, }}>{item[2]} Points {item[9]}/{item[8]+item[9]}</Text>
                : null}
                
            </TouchableOpacity> 
        )
       
    }
    AddPlayerNameAndNo = (HomeOrAway) => {
        if (HomeOrAway === 'home') {
            for (var x in this.props.homeTeamStats ) {                
                var m=0
                if (this.props.homeTeamStats[x][1] === this.state.homeTeamPlayerNo || this.state.homeTeamPlayerNo === '' || isNaN(this.state.homeTeamPlayerNo)) {
                   m =1
                    this.setState({homeTeamPlayerName: ''})
                    this.setState({homeTeamPlayerNo: ''})
                    break
                }
            }           
            if (m!==1)    {                   
                    this.props.updateHomeTeamStats(this.props.homeTeamStats.concat([[this.state.homeTeamPlayerName,this.state.homeTeamPlayerNo,0,0,0,0,0,0,0,0]]))                   
                    this.setState({homeTeamPlayerName: ''})
                    this.setState({homeTeamPlayerNo: ''})
            }
        
        }
        else {
            for (var x in this.props.awayTeamStats ) {           
                var m=0
                if (this.props.awayTeamStats[x][1] === this.state.awayTeamPlayerNo || this.state.awayTeamPlayerNo === '' || isNaN(this.state.awayTeamPlayerNo)) {
                    m =1
                    this.setState({awayTeamPlayerName: ''})
                    this.setState({awayTeamPlayerNo: ''})
                    break
                }
            }           
            if (m!==1)    {               
                this.props.updateAwayTeamStats(this.props.awayTeamStats.concat([[this.state.awayTeamPlayerName,this.state.awayTeamPlayerNo,0,0,0,0,0,0,0,0]]))               
                this.setState({awayTeamPlayerName: ''})
                this.setState({awayTeamPlayerNo: ''})
            }
        }

    }

    test=(key, HomeOrAway)=>{
       
        if (HomeOrAway === 'home') {
            for (var x in this.props.homeTeamStats ) {                
                var m=0
                if (this.props.homeTeamStats[x][1] === this.state.homeTeamPlayerNo || this.state.homeTeamPlayerNo === '' || isNaN(this.state.homeTeamPlayerNo)) {
                   m =1
                    this.setState({homeTeamPlayerName: ''})
                    this.setState({homeTeamPlayerNo: ''})
                    break
                }
            }           
            if (m!==1)    {
                    this.props.homeTeamStats[key][0]=this.state.homeTeamPlayerName;
                    this.props.homeTeamStats[key][1]=this.state.homeTeamPlayerNo;
                    this.props.updateHomeTeamStats(this.props.homeTeamStats)
                   // this.checkForANyNameInput()
                    this.setState({homeTeamPlayerName: ''})
                    this.setState({homeTeamPlayerNo: ''})
            }
        
        }
        else {
            for (var x in this.props.awayTeamStats ) {
                console.log(this.props.awayTeamStats[x][1])
                var m=0
                if (this.props.awayTeamStats[x][1] === this.state.awayTeamPlayerNo || this.state.awayTeamPlayerNo === '' || isNaN(this.state.awayTeamPlayerNo)) {
                   m =1
                    this.setState({awayTeamPlayerName: ''})
                    this.setState({awayTeamPlayerNo: ''})
                    break
                }
            }           
            if (m!==1)    {
                this.props.awayTeamStats[key][0]=this.state.awayTeamPlayerName;
                this.props.awayTeamStats[key][1]=this.state.awayTeamPlayerNo;
                this.props.updateAwayTeamStats(this.props.awayTeamStats)
                //this.checkForANyNameInput()
                this.setState({awayTeamPlayerName: ''})
                this.setState({awayTeamPlayerNo: ''})
            }
        }
       
    }

    checkForANyNameInput = () => {
        for (var i in this.props.homeTeamStats) {
            if (this.props.homeTeamStats[i][1]!== ''){
                for (var m in this.props.awayTeamStats) {                    
                    if (this.props.awayTeamStats[m][1]!== ''){
                        this.setState({dashboard:true})
                        this.setState({editState:false})
                        break
                    }
                    else{
                        this.setState({dashboard:false})
                    }
                   
                }
                break
            }
            else{
                this.setState({dashboard:false})
            }
        }
    }
    playDashBoard = () => {   
        return (
            
            
            <View style={{flex: 1.7, backgroundColor:'#F4781C'}}>
                <TouchableOpacity onPress={() => this.saveStatsOnline()} style={{color: 'white',height:40, width:60, backgroundColor:'#760707', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Save</Text>
                        </TouchableOpacity>
                                 
                <View style={{marginLeft:10, justifyContent:'center', flex:0.7,}}>
                    <Text style={{ fontSize: 40, alignSelf:'center', color:'white'}}>{this.state.homeTeamPoints}</Text>
                </View>
                <View style={{flex:1.5}}>
                    <View style={{marginLeft:10, justifyContent:'space-evenly', flexDirection:'row', marginBottom:10}}>
                        
                        <TouchableOpacity onPress={() => this.AddStats('freethrow')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Free Throw</Text>
                        </TouchableOpacity>
                                 
                       
                        <TouchableOpacity onPress={() => this.AddStats('twopoints')}  style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>2 points</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.AddStats('threepoints')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>3 points</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{marginLeft:10, justifyContent:'space-evenly', flexDirection:'row', marginBottom:10}}>
                        <TouchableOpacity onPress={() => this.AddStats('steal')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Steal</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.AddStats('turnover')}  style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Turn Over</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.AddStats('rebound')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Rebounds</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{marginLeft:10, justifyContent:'space-evenly', flexDirection:'row'}}>
                        <TouchableOpacity onPress={() => this.AddStats('block')} style={{color: 'white', height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Blocks</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.AddStats('miss')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Miss</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.AddStats('assist')} style={{color: 'white',height:40, width:90, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                            <Text style={{alignSelf:'center',}}>Assists</Text>
                        </TouchableOpacity>
                    </View>
                    
                </View>
                <View style={{marginLeft:10, justifyContent:'center', flex:0.7,}}>
                    <Text style={{ fontSize: 40, alignSelf:'center', color:'white'}}>{this.state.awayTeamPoints}</Text>
                </View>
            </View>
           
           
        )
        
    }
    
    render() {  
                 
      return (
          
        <View style={{  }}>
            <View>{                
                !this.state.dashboard && this.state.editState ? 
                <View style={{color: 'white',height:40,  backgroundColor:'brown', justifyContent:'center', borderRadius:5, flexDirection:'row' }}>
                    
                    <TouchableOpacity onPress = {() => this.setState({dashboard:true,editState:false})} style={{alignSelf:'center', color: 'white',height:30, width:50, backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                        <Text style={{alignSelf:'center',}}>
                            Save
                        </Text>
                    </TouchableOpacity> 
                </View> 
 
                :
                this.state.selectTeamNote ?
                <TouchableOpacity style={{color: 'brown',height:40,  backgroundColor:'white', justifyContent:'center', borderRadius:5, }}>
                    <Text style={{alignSelf:'center',}}>
                        Select a Player
                    </Text>
                </TouchableOpacity>     
                :
                <View style={{color: 'white',height:40,  backgroundColor:'brown', justifyContent:'center', borderRadius:5, flexDirection:'row' }}>
                     <TouchableOpacity onPress={() => this.removeLastPlay() }style={{paddingLeft:3, paddingRight:3,alignSelf:'center', marginLeft: 10, color: 'white',height:30,  backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                        <Text style={{alignSelf:'center',}}>
                            undo last play
                        </Text>
                    </TouchableOpacity>  

                    <TouchableOpacity onPress={() => this.setState({editState:true, dashboard:false})} style={{paddingLeft:3, paddingRight:3, alignSelf:'center', marginLeft: 10, color: 'white',height:30,  backgroundColor:'blue', justifyContent:'center', borderRadius:5, }}>
                        <Text style={{alignSelf:'center',}}>
                            Edit player name or number
                        </Text>
                    </TouchableOpacity>  
                </View>
                }
                
            </View>
            <View style={{flex:1,}}>
                
            {/*<Text style ={{fontSize:18,  alignSelf:'center',flex:0  }}>{!this.state.dashboard  && this.state.editState ? 'Home Team': null} </Text>*/}
              
                <ScrollView horizontal={true} style={{paddingTop:0,paddingLeft:5, flex:1, paddingBottom:0, minHeight:150 }}>
               
                {                     
                    this.props.homeTeamStats.map(( item, key ) =>
                        (
                           
                            this.state.editState ?
                            <Popup
                                trigger={<button className="button">{this.homeTeamPlayerInfo(item)}</button>}
                                modal
                                closeOnDocumentClick>
                                {close => (
                                    <View> 
                                        <TextInput
                                            style={{height: 40, backgroundColor:'#DFDEDE'}}
                                            placeholder="Player Name"
                                            onChangeText={(homeTeamPlayerName) => this.setState({homeTeamPlayerName})}
                                            value={this.state.homeTeamPlayerName}
                                        />
                                        <TextInput                                
                                            style={{height: 40, backgroundColor:'#DFDEDE', marginTop:5}}
                                            placeholder="Player #"
                                            onChangeText={(homeTeamPlayerNo) => this.setState({homeTeamPlayerNo})}
                                            value={this.state.homeTeamPlayerNo}
                                        />  
                                        <button
                                            style={{fontSize:15,alignSelf:'center', marginTop:7, color: 'white',height:30, width:50, backgroundColor:'#807E7E', justifyContent:'center', borderRadius:5, }}
                                            onClick={() => {this.test(key, 'home'); close();}}>
                                            Enter
                                        </button>
                                    </View>
                                    )
                                }
                            </Popup> 
                            :
                             <button> 
                                {this.homeTeamPlayerInfo(item)} 
                            </button>                
                        )
                        
                        
                    )
                    
                }
                {
                    this.state.editState ?
                    null
                    :
                    <Popup
                        trigger={<button className="button">{this.AddPlayerInfoView()}</button>}
                        modal
                        closeOnDocumentClick>
                        {close => (
                            <View> 
                                <TextInput
                                    style={{height: 40, backgroundColor:'#DFDEDE'}}
                                    placeholder="Player Name"
                                    onChangeText={(homeTeamPlayerName) => this.setState({homeTeamPlayerName})}
                                    value={this.state.homeTeamPlayerName}
                                />
                                <TextInput                                
                                    style={{height: 40, backgroundColor:'#DFDEDE', marginTop:5}}
                                    placeholder="Player #"
                                    onChangeText={(homeTeamPlayerNo) => this.setState({homeTeamPlayerNo})}
                                    value={this.state.homeTeamPlayerNo}
                                />  
                                <button
                                    style={{fontSize:15,alignSelf:'center', marginTop:7, color: 'white',height:30, width:50, backgroundColor:'#807E7E', justifyContent:'center', borderRadius:5, }}
                                    onClick={() => {this.AddPlayerNameAndNo('home'); close();}}>
                                    Enter
                                </button>
                            </View>
                            )
                        }
                    </Popup>
                }
                </ScrollView>
              
            </View>
            
            {
                this.state.dashboard
                ?
                this.playDashBoard()
             
                :
                
                null
               
            }
            
            
            <View style={{flex:1, }}>
            
            {/*<Text style ={{fontSize:18,  alignSelf:'center',flex:0  }}>{!this.state.dashboard  && this.state.editState ? 'Away Team': ''} </Text>*/}
            
            <ScrollView horizontal={true} style={{flex:1, paddingTop:0,paddingLeft:5, paddingBottom:0,marginBottom:11,minHeight:150 
            
            }}>
           
            {                     
                this.props.awayTeamStats.map(( item, key ) =>
                    (
                      
                        this.state.editState ?
                        <Popup
                            trigger={<button className="button">{this.awayTeamPlayerInfo(item)}</button>}
                            modal
                            closeOnDocumentClick>
                            {close => (
                                <View> 
                                    <TextInput
                                        style={{height: 40, backgroundColor:'#DFDEDE'}}
                                        placeholder="Player Name"
                                        onChangeText={(awayTeamPlayerName) => this.setState({awayTeamPlayerName})}
                                        value={this.state.awayTeamPlayerName}
                                    />
                                    <TextInput                                
                                        style={{height: 40, backgroundColor:'#DFDEDE', marginTop:5}}
                                        placeholder="Player #"
                                        onChangeText={(awayTeamPlayerNo) => this.setState({awayTeamPlayerNo})}
                                        value={this.state.awayTeamPlayerNo}
                                    />  
                                    <button
                                        style={{fontSize:15,alignSelf:'center', marginTop:7, color: 'white',height:30, width:50, backgroundColor:'#807E7E', justifyContent:'center', borderRadius:5, }}
                                        onClick={() => {this.test(key, 'away'); close();}}>
                                        Enter
                                    </button>
                                </View>
                                )
                            }
                        </Popup> 
                        :
                        <button>
                            {this.awayTeamPlayerInfo(item)}   
                        </button>                            
                    )
                )
            }
            {
                this.state.editState ?
                null
                :
                <Popup
                    trigger={<button className="button">{this.AddPlayerInfoView()}</button>}
                    modal
                    closeOnDocumentClick>
                    {close => (
                        <View> 
                            <TextInput
                                style={{height: 40, backgroundColor:'#DFDEDE'}}
                                placeholder="Player Name"
                                onChangeText={(awayTeamPlayerName) => this.setState({awayTeamPlayerName})}
                                value={this.state.awayTeamPlayerName}
                            />
                            <TextInput                                
                                style={{height: 40, backgroundColor:'#DFDEDE', marginTop:5}}
                                placeholder="Player #"
                                onChangeText={(awayTeamPlayerNo) => this.setState({awayTeamPlayerNo})}
                                value={this.state.awayTeamPlayerNo}
                            />  
                            <button
                                style={{fontSize:15,alignSelf:'center', marginTop:7, color: 'white',height:30, width:50, backgroundColor:'#807E7E', justifyContent:'center', borderRadius:5, }}
                                onClick={() => {this.AddPlayerNameAndNo('away'); close();}}>
                                Enter
                            </button>
                        </View>
                        )
                    }
                </Popup>  
            }
            </ScrollView>
            </View>
        </View>

        

      );
      
    }
  }

  const mapStateToProps = state => {
    return { 
        homeTeamStats: state.homeTeamStats,
        awayTeamStats: state.awayTeamStats,
        playMatrix: state.playMatrix 
    }  
}

export default connect(mapStateToProps, actions)(Developer);
//<Text style ={{fontSize:18, marginBottom:-50, alignSelf:'center',  }}>{this.state.dashboard  && this.state.editState ? 'Away Team': null} </Text>
/*
<Popup
                trigger={
                    <button className="button">
                        <View style={{color: 'white',width:'100%',}}>
                            <TouchableOpacity onPress = { () => this.checkForANyNameInput()} style={{alignSelf:'center',color: 'white',height:40, width: 70, backgroundColor:'brown', justifyContent:'center', borderRadius:5, }}>
                                <Text style={{alignSelf:'center'}}>Start</Text>
                            </TouchableOpacity >
                        </View>
                    </button>
                    }
                    modal
                    closeOnDocumentClick >
                    <View>  
                        <Text>Add Home and Away team Players' Name</Text>  
                                    
                    </View>
                </Popup> 
*/
  


/*
import * as React from 'react';
import { Text, TextInput, View, Dimensions, Button, TouchableOpacity } from 'react-native';
import { Player, ControlBar } from 'video-react';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Amplify, { Auth, Storage, API, graphqlOperation,  withAuthenticator, } from 'aws-amplify';
import { PhotoPicker,  SignIn, Greetings, Authenticator,ConfirmSignIn } from 'aws-amplify-react';



class Developer extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {            
            Imageratio:1,
            PageSizeWidth:Dimensions.get('window').width ,
            schoolName: '',
            date:'',
            topic:'',
            story:'',
            storyNo:'',
            storyType:'',
            readTime:'',
            fileType:'',
            author:''
        }
        
    }

   

    onChange(e) {
        const file = e.target.files[0];
        Storage.put('headlines/example', file, {
            //contentType: 'image/png'
        })
        .then (result => console.log(result))
        .catch(err => console.log(err));
    }
    
    UploadNewInfo = () => {
        console.log(this.state.schoolName+' '+this.state.date+' '+this.state.topic+' '+this.state.story+' '+this.state.author+' '+this.state.readTime)
        const standing = `
            mutation add ($schoolName:String! $date:String! $topic:String! $story:String! $author:String! $readTime:String!) {
                addStory(schoolName: $schoolName, date:$date, topic:$topic, story:$story, author:$author, readTime:$readTime) {
                date
                }
            }`;
           
            API.graphql(graphqlOperation(standing, { schoolName:this.state.schoolName, date:this.state.date, topic:this.state.topic, story:this.state.story, author:this.state.author, readTime:this.state.readTime})).then(response => { 
                console.log(response)
             }).catch(e => {console.log(e);});
    }

   

    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
        window.scrollTo(0, 0)
        this.setState({pageURL: window.location.href})
        this.UploadNewInfo()

        Auth.signUp({
            username:'omoni',
            password:'Testingpassword1%',
            attributes: {
                email:'allamericanhighschoolsports@gmail.com',          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes 
            },
            validationData: []  //optional
            })
            .then(data => console.log(data))
            .catch(err => console.log(err));
      
       
    }
    handleResize = () => {
        console.log(Dimensions.get('window'))
        if (Dimensions.get('window').width<=770){
        this.setState({PageSizeWidth:Dimensions.get('window').width, Imageratio:Dimensions.get('window').width/700 })
        }
        else {
        this.setState({PageSizeWidth:700,})
        }
    }

    render() {
        return (
            <View style={{paddingBottom:20,  flex:1,backgroundColor:'#D0D3D5', paddingBottom:10, marginBottom:20,  }} >
                <View style = {{padding:10, flex:1, flexDirection:'row',  backgroundColor:'white', width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',}}>
                            
                    <View style={{padding: 10}}>
                        <View style={{flexDirection:'row', }}>
                            <Text style ={{ alignSelf:'center',}}>SchoolName (Lamar - 6A - Football)</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" Lamar - 6A - Football"
                                onChangeText={(schoolName) => this.setState({schoolName})} value={this.state.schoolName}
                            />
                        </View>  
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>Date (2019-10-26)</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" 2019-10-26"
                                onChangeText={(date) => this.setState({date})} value={this.state.date}
                            />
                        </View>       
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>Headlines</Text>
                            <TextInput style={{height: 40, flex:1, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" Who are these Sharpstown Apollo boy's soccer team?"
                                onChangeText={(topic) => this.setState({topic})} value={this.state.topic}
                            />
                        </View> 
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>Story</Text>
                            <TextInput style={{height: 100, width:500, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder="Lamar - 6A - Football"
                                onChangeText={(story) => this.setState({story})} value={this.state.story}
                                multiline numberOfLines={4}
                            />
                        </View> 
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>StoryNo</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" 1"
                                onChangeText={(storyNo) => this.setState({storyNo})} value={this.state.storyNo}
                            />
                        </View> 
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>readTime</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" 1"
                                onChangeText={(readTime) => this.setState({readTime})} value={this.state.readTime}
                            />
                        </View> 
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>author</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" Omo"
                                onChangeText={(author) => this.setState({author})} value={this.state.author}
                            />
                        </View>
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>StoryType(i.e. podcast)</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" podcast"
                                onChangeText={(storyType) => this.setState({storyType})} value={this.state.storyType}
                            />
                        </View>   
                        <View style={{flexDirection:'row', marginTop:20}}>
                            <Text style ={{ alignSelf:'center',}}>FileType(i.e. video)</Text>
                            <TextInput style={{height: 40, marginLeft:20, backgroundColor:'#F2F0F0'}} placeholder=" video"
                                onChangeText={(fileType) => this.setState({fileType})} value={this.state.fileType}
                            />
                        </View>  
                        <input
                            type="file" 
                            // onChange={(e) => this.onChange(e)}
                            /> 
                       <TouchableOpacity onPress={() => { this.UploadNewInfo()}}>
                                            
                            <Text style={{ width:100,height:30,fontSize:15, fontFamily: 'Helvetica' , backgroundColor:'red' }}>
                                                Save  
                            </Text>            
                        </TouchableOpacity>
                      
                    </View>
                </View>
                <Authenticator 
    // Optionally hard-code an initial state
    authState="signIn"
    // Pass in an already authenticated CognitoUser or FederatedUser object
    authData={ 'username'} 
    // Fired when Authentication State changes
    onStateChange={(authState) => console.log(authState)} 
    // An object referencing federation and/or social providers 
    // The federation here means federation with the Cognito Identity Pool Service
    // *** Only supported on React/Web (Not React Native) ***
    // For React Native use the API Auth.federatedSignIn()
    federated={'myFederatedConfig'}
    // A theme object to override the UI / styling
    theme={'myCustomTheme'} 
    // Hide specific components within the Authenticator
    // *** Only supported on React/Web (Not React Native)  ***
    
    // or hide all the default components
    //hideDefault={true}
    // Pass in an aws-exports configuration
    amplifyConfig={'myAWSExports'}
    // Pass in a message map for error strings
    errorMessage={'myMessageMap'}
>

</Authenticator>
            </View>
        );
    }
}
export default Developer
*/