import React , { Component } from 'react';
import { Platform, Text, StyleSheet, View, Image, ImageBackground, ScrollView, Dimensions, FlatList} from 'react-native';
//import { Card, Button, List, ListItem, Badge, Icon, SocialIcon, Avatar, Text, Divider, ButtonGroup } from 'react-native-elements';

import moment from "moment";
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";

const { width, height } = Dimensions.get('window');
const widthScale = width / 320;

class Fixtures  extends Component {

    
    _renderItem = ({ item, index }) => { 
        if (item.awayTeam.includes(this.props.currentTeam))  {    
            return (
                <View style={{paddingBottom: 15,}}>
                    <View style={{ flexDirection: 'row', paddingTop: 15, justifyContent: 'center', textAlign: 'center',flex:1, backgroundColor: item.league ? '#282827' : 'white'}} >            
                        <Text style={{fontWeight:500, fontfamily: 'Helvetica' , alignSelf: 'center', fontSize: 15, color: 'black', flex:1.8, paddingLeft:15 }}>{ moment(item.gameTime).format("ddd, MMM D")}</Text>
                        <View style={{flex: 3,flexDirection:'row', alignSelf: 'center', justifyContent:'center',}}>
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginRight:10}}
                                                            source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' +  item.homeTeam.split('-')[0] + '- ' + item.homeTeam.split('-')[1].trim() + '/logo'}} 
                                                 
                                                 />
                      
                            <Text style={{ fontWeight:500, fontfamily: 'Helvetica' ,fontSize: 15,  textAlign: 'center', color: 'black', }}>
                                {'@ ' + item.homeTeam.split('-')[0]}</Text>
                        </View>
                        <Text style={{ fontWeight:500, fontfamily:  'Helvetica' ,backgroundColor:this.updateBackgroundColor( item.awayTeamScore ,item.homeTeamScore), textAlign: 'center', 
                            alignSelf: 'center', justifyContent: 'center', fontSize: 15, color: item.homeTeamScore ? 'white' : 'black', flex: 1.5, marginRight:15}}>
                            { item.awayTeamScore ?  item.awayTeamScore  + ' - ' + item.homeTeamScore : moment(item.gameTime + ' ' + item.time).format("LT")}</Text>        
                    </View>
                    <Text style={{ fontWeight:450, fontfamily: 'Helvetica' ,paddingLeft:60 , paddingTop:5, fontSize: 15, color: '#787A7C', flex: 3, }}>
                            Stadium: {item.venue}</Text>
                </View>
            )
        }
        else {
            return (
                <View style={{paddingBottom: 15,}}>
                    <View style={{ flexDirection: 'row', paddingTop: 15, justifyContent: 'center', textAlign: 'center',flex:1, backgroundColor: item.league ? '#282827' : 'white'}} >            
                    <Text style={{fontWeight:500,fontfamily: 'Helvetica' , alignSelf: 'center', fontSize: 15, color: 'black', flex:1.5, paddingLeft:15 }}>{ moment(item.gameTime).format("ddd, MMM D")}</Text>
                    <View style={{flex: 3,flexDirection:'row', alignSelf: 'center', justifyContent:'center',}}>
                            <Image style={{width: 25, height: 20,  backgroundColor:'white', alignSelf:'center', marginRight:10}}
                                                            source={{uri: 'https://certificate-for-fanzquiz.s3.amazonaws.com/public/schools/' +  item.awayTeam.split('-')[0] + '- ' + item.awayTeam.split('-')[1].trim() + '/logo'}} 
                                                 
                                                 />
                    
                            <Text style={{fontWeight:500, fontfamily: 'Helvetica' ,  textAlign: 'center', fontSize: 15, color: 'black',}}>
                                {item.awayTeam.split('-')[0]}</Text>
                    </View>
                    <Text style={{fontWeight:500, fontfamily: 'Helvetica' ,backgroundColor:this.updateBackgroundColor(item.homeTeamScore, item.awayTeamScore), textAlign: 'center',
                        alignSelf: 'center', justifyContent: 'center', fontSize: 15, color: item.homeTeamScore ? 'white' : 'black', flex: 1.5, marginRight:15}}>
                        {item.homeTeamScore ? item.homeTeamScore + ' - ' + item.awayTeamScore : moment(item.gameTime + ' ' + item.time).format("LT")}</Text>        
                    </View>
                    <Text style={{ fontWeight:450, fontfamily: 'Helvetica' ,paddingLeft:60 , paddingTop:5, fontSize: 15, color: '#787A7C', flex: 3, }}>
                            Stadium: {item.venue}</Text>

                </View>
            )
        }
    
    };

    updateBackgroundColor = (homeTeamScore, awayTeamScore) => {        
        if (homeTeamScore != null && homeTeamScore != '-') {            
            if (Number(homeTeamScore) > Number(awayTeamScore)) {return 'green'}
            else if (Number(homeTeamScore)< Number(awayTeamScore)) {return 'red'}
            else  {return '#8D9193'}
        }
        else { return ''}    
    }


    _renderItemNothing = ({ item, index }) => (
        <View style={{ flexDirection: 'row', marginTop: 7, marginBottom: 7, justifyContent: 'center', textAlign: 'center', }} >
        </View>
    );





    renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: "93%",
                    backgroundColor: "#434546",  
                    alignSelf:'center'                 
                }}
            />
        );
    };
/*
    fixtureProps = () => {
        return (
            [{league: 'EPL',date: 'Sun,Feb 24',team: 'vs SHN',time: '8:05 AM',}, {league: 'EPL',date: 'Wed,Feb 27',team: 'vs BOU',time: '1:45 PM',}, {month: 'March 2019'},{league: 'EPL',date: 'Wed,Feb 27',team: 'vs BOU',time: '1:45 PM',}]
        )
    }
*/
   render () {
       
    return (
        <AmplitudeProvider
            amplitudeInstance={amplitude.getInstance()}
            apiKey='265ecd4214dc923fea7ba20a854373ff'               
            >  
            <Amplitude
                eventProperties={{                        
                "School Name": this.props.currentTeam,  
                }}
                >               
            <LogOnMount eventType="SchedulePage" />
            <View style={{backgroundColor:'white',}}>
                
                <FlatList                    
                data = {this.props.fixtureData}
                renderItem={this._renderItem }
                ItemSeparatorComponent={this.renderSeparator}          
                />
            </View>
            </Amplitude>
        </AmplitudeProvider>
       
    )
   }
    
}

export default Fixtures