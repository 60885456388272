export default (state = [], action) => {
    
    switch (action.type) {
        
        case 'player_detail':  
            console.log('playerdetailinfo')
            console.log(action.payload)
            console.log('playerdetailinfo')
           return action.payload;
        default:
            return state;
    }
};