import React, { Component } from 'react';
import { Platform, StyleSheet, View, Image, Text, BackHandler, ImageBackground, ScrollView, TouchableOpacity} from 'react-native';
import { Dimensions, Animated } from 'react-native';
//import { Button, List, ListItem, Badge, SocialIcon, Avatar,  Divider, ButtonGroup,  } from 'react-native-elements';
import * as actions from './actions';
import { connect } from 'react-redux';
//import {PagerTabIndicator, IndicatorViewPager, PagerTitleIndicator, PagerDotIndicator} from 'rn-viewpager';
import TeamOverview from './TeamOverview';
import Fixtures from './Fixtures';
import Table from './Table';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import PlayerPage from './PlayerPage';
import NewsPage from './NewsPage';
import { FaFootballBall } from 'react-icons/fa';
import { FaBaseballBall } from 'react-icons/fa';
import { FaBasketballBall } from 'react-icons/fa';
import { FaFutbol } from 'react-icons/fa';
import { FaVolleyballBall } from 'react-icons/fa';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { FaCog} from 'react-icons/fa';
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";

const moment = require('moment-timezone');

//import Icon from 'react-native-vector-icons/FontAwesome';
//import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
//import moment from "moment";


const HEADER_EXPANDED_HEIGHT = 150
const HEADER_COLLAPSED_HEIGHT = 40  
const { width: SCREEN_WIDTH } = Dimensions.get('screen')
const { width, height } = Dimensions.get('window');
const widthScale = width / 320;
const remote = 'https://www.gstatic.com/webp/gallery/1.jpg';

class AppSettingPage extends Component {
   
    constructor(props) {
        super(props);
        this.state = {      
          playerData:[],                  
          scrollY: new Animated.Value(0),
          pageNo: 0, 
          state_nextgameprops : -1,            
          scheduledatearrive:0,
          scheduleData:[],
          schoolName:'',
          sport:'',
          district:'',
          PageSizeWidth:Dimensions.get('window').width,
          overall:'',
          district:''
        }        
    }
   
    GetTheStats = (districtStanding) => {
      console.log(districtStanding)
      console.log('powerhouse')
      for (var school in districtStanding) {
        console.log('powerhouse')
        console.log(school)
        if (districtStanding[school].schoolName === this.props.currentTeamSel.split('_').join(' ').split('-')[0].trim()){
          var overall = districtStanding[school].stats[2] + '-' + districtStanding[school].stats[3]
          var district = districtStanding[school].stats[0] + '-' + districtStanding[school].stats[1]
         
          this.setState({overall: overall, dstrict: district});
          break
        }
      }
      
    }

   

    updateGameTime = (data) => {             
      var date = moment.utc().format();
      var local = moment.utc(date).local().format();
      var localTime = moment(local)
      console.log(localTime)
      this.setState({ state_nextgameprops : '' })
      
      for (var x in data) {            
          var gameDate  = moment.tz(data[x].gameTime,"America/Chicago")
          console.log(gameDate)
          var timea = moment (gameDate)
          console.log(timea)
          if (localTime.isAfter(timea)) {this.setState({ state_nextgameprops : Number(x)+1 });}
          else if (this.state.state_nextgameprops === -1){this.setState({ state_nextgameprops : 0 });}
          else {this.props.updateNextGameElement(x); break;}
      }   
      
      console.log(this.state.state_nextgameprops)
     this.props.updatePropsElement(this.state.state_nextgameprops)
     this.props.updateGameFixtures(data);
  }



  fixturesData = (schoolName) => {
      //this.setState(() => {return { fixtureData: [], next_game_element : '', props_element : '',}});
      console.log(schoolName)
      this.props.updateGameFixtures([])
      this.props.updateNextGameElement('')
      this.props.updatePropsElement('')
      
       
      const ScheduleAwayTeam = `
          query ScheduleAwayTeam ($awayTeam: String!) {
              ScheduleAwayTeam (awayTeam: $awayTeam)  {
                  items{ gameTime awayTeam homeTeam awayTeamScore homeTeamScore time sport gameStatus venue}
              }
          }`;
      API.graphql(graphqlOperation(ScheduleAwayTeam, {awayTeam: schoolName})).then(response => {
         
          if (this.state.scheduledatearrive != 0){
              this.setState ({ scheduleData:this.state.scheduleData.concat(response.data.ScheduleAwayTeam.items)})
              this.state.scheduleData.sort(function(a,b){return moment(a.gameTime) - moment(b.gameTime)});

              

              this.updateGameTime(this.state.scheduleData)    
              this.setState ({ scheduleData:[], scheduledatearrive:0})         
          }
          else {
              this.setState({scheduleData: response.data.ScheduleAwayTeam.items, scheduledatearrive:1})
              console.log(this.state.scheduleData)                                
          }            
      }).catch(e => {console.log(e);});

      const ScheduleHomeTeam = `
          query ScheduleHomeTeam ($homeTeam: String!) {
              ScheduleHomeTeam (homeTeam: $homeTeam)  {
                  items{ gameTime awayTeam homeTeam awayTeamScore homeTeamScore time sport gameStatus venue}
              }
          }`;
      API.graphql(graphqlOperation(ScheduleHomeTeam, {homeTeam: schoolName})).then(response => {
         
          if (this.state.scheduledatearrive != 0){
              this.setState ({ scheduleData:this.state.scheduleData.concat(response.data.ScheduleHomeTeam.items)})                 
              this.state.scheduleData.sort(function(a,b){return moment(a.gameTime) - moment(b.gameTime)});
              

              this.updateGameTime(this.state.scheduleData);  
              this.setState ({ scheduleData:[], scheduledatearrive:0})                  
          }
          else {
              this.setState({scheduleData: response.data.ScheduleHomeTeam.items, scheduledatearrive:1})    
              console.log(this.state.scheduleData)            
          }            
      }).catch(e => {console.log(e);});
  }
    
   
  rosterData = (schoolName) => {
      console.log(schoolName)
      this.props.updateGameRosters([])
      const roster = `
        query roster ($schoolName: String!) {
          roster (schoolName : $schoolName)  {
            items{ schoolName news players newsInfo}
          }
        }`;
      API.graphql(graphqlOperation(roster, {schoolName})).then(response => {
          //this.playerDatialInfo(typeof response.data.roster.items[0] == 'undefined' ? '' : response.data.roster.items[0].players)
          
          this.props.updateGameRosters(response.data.roster.items[0]);
          var PageURL = window.location.href.split("/")
          function CheckIndex(element) {
              return element === 'roster';
          }          
          if (PageURL.includes('roster')) {  
           
            var arr = [];      
            for (var indPlayer in this.props.rosterData.players){arr.push(this.props.rosterData.players[indPlayer].split('[<>]')[0].trim());}                                     
            console.log(this.props)
            //this.props.UpdatePlayerIndex(key); 
            this.props.updatePlayerDetailInfo(arr);

             var rosterIndex = PageURL.findIndex(CheckIndex)
             this.props.rosterData.players.find((o, i) => {                                      
                  if (o.split("[<>]")[1] === PageURL[rosterIndex+1].replace(/%20/g, " ").replace(/%E2%80%99/g, "'")) {
                    this.props.UpdatePlayerIndex(i); 
                  }
                  
            })
              
          }
      }).catch(e => {console.log(e);});
  }

  teamNewsData = (schoolName) => {
    console.log(schoolName)
      this.props.updateTeamNewsData([])
      const TeamHeadlines = `
      query TeamHeadlines ($schoolName: String!) {
          TeamHeadlines (schoolName : $schoolName)  {
              items{ schoolName date author readTime story topic fileType}
          }
      }`;
      API.graphql(graphqlOperation(TeamHeadlines , {schoolName})).then(response => {
         
          this.props.updateTeamNewsData(response.data.TeamHeadlines.items);
          var PageURL = window.location.href.split("/")
          function CheckIndex(element) {
              return element === 'news';
          }          
          if (PageURL.includes('news')) {  
            var newsIndex = PageURL.findIndex(CheckIndex)   
              this.props.teamNewData.find((o, i) => {                               
                  if (o.topic === PageURL[newsIndex+1].replace(/%20/g, " ").replace(/%E2%80%99/g, "'")) {
                    this.props.UpdateNewPageIndex(i)
                  }
                 })
              
          }
      }).catch(e => {console.log(e);});
  }

  StandingData = (regionName) => {        
      this.props.updateGameStanding([])
      const standing = `
        query standing ($regionName: String!) {
          standing (regionName: $regionName)  {
            items{regionName points stats schoolName}
          }
        }`;
      API.graphql(graphqlOperation(standing, { regionName: regionName })).then(response => { 
        this.GetTheStats(response.data.standing.items)          
          this.props.updateGameStanding(response.data.standing.items)
      }).catch(e => {console.log(e);});
  }

   componentDidMount() {
 
    console.log(this.props.currentTeamSel)
    this.handleResize()
    window.addEventListener('resize', this.handleResize);

    var PageURL = window.location.href.split("/")
    function TeamIndex(element) {
        return element === 'teams';
    }    

    var teamIndex = PageURL.findIndex(TeamIndex) 
    var passvar = PageURL[teamIndex +1].replace(/%20/g, " ")
    console.log(PageURL[teamIndex +2].split("-")[0])
    var sport = PageURL[teamIndex +2].split("-")[0] +  (PageURL[teamIndex +2].length===3 ? ' - ' + PageURL[teamIndex +2].split("-")[2] : '')
    this.setState({sport: sport, schoolName: passvar, district: PageURL[teamIndex +2].split("-")[1] })
    console.log(sport)
    
    this.props.updateCurrentSelectedTeam( passvar + ' ' + sport)
    this.teamNewsData(passvar.split('-')[0]  + ' - ' + passvar.split('-')[1] + ' - ' + sport)
    this.fixturesData(passvar.split('-')[0]  + ' - ' + passvar.split('-')[1] + ' - ' + sport)
    this.rosterData(passvar.split('-')[0] + '-' + sport)
    console.log('zzzzzzz')
    console.log(PageURL[teamIndex +2].split("-")[1])
    console.log(passvar.split('-')[1] + '-' + PageURL[teamIndex +2].split("-")[1] + '-' + sport)
    this.StandingData(PageURL[teamIndex +2].split("-")[1] + '-' + passvar.split('-')[1] + '-' + sport)
   
  
  }

  componentWillUnmount() {
     
  }

  sportImage = (children ) => {      
    switch (children) {
        case 'Soccer':
            return <FaFutbol size={15} color='#b3b3b3' style={{paddingTop:5, marginRight:5}}/>              
        case 'Football':
            return <FaFootballBall size={15} color='#b3b3b3' style={{paddingTop:6, marginRight:5}}/>                
        case 'Baseball':
            return <FaBaseballBall size={15} color='#b3b3b3' style={{paddingTop:6, marginRight:5}}/>                 
        case 'Volleyball':
            return <FaVolleyballBall size={15} color='#b3b3b3' style={{paddingTop:6, marginRight:5}}/>             
        case 'Softball':
            return <FaBaseballBall size={15} color='#b3b3b3' style={{paddingTop:6, marginRight:5}}/>  
        case 'basketball':
            return <FaBasketballBall size={15} color='#b3b3b3' style={{paddingTop:6, marginRight:5}}/>              
        default:
            return  <View></View>      
     }  
}

  handleResize = () => {
        console.log(Dimensions.get('window'))
        if (Dimensions.get('window').width<=600){
        this.setState({PageSizeWidth:Dimensions.get('window').width, })
        }
        else {
        this.setState({PageSizeWidth:600,})
        }
    }

    MainPageFun = () => {
        console.log('checking for mainpage')
      const headerTitle = this.props.currentTeamSel.split('_').join(' ')
      console.log(headerTitle )
      return (
        <AmplitudeProvider
          amplitudeInstance={amplitude.getInstance()}
          apiKey='265ecd4214dc923fea7ba20a854373ff'               
          >  

            <Amplitude
                        eventProperties={{                        
                        "School Name": this.state.schoolName.split('-')[0],
                        }}
                        >                 
                                    
          <LogOnMount eventType="TeamPage" />
          <View style={{ marginBottom:30, marginTop:-20, backgroundColor:'#D0D3D5', minHeight: Dimensions.get('window').height  }} >
                
              
              <View style={{flex:1, marginTop:10, width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',borderRadius:50, marginBottom:30,backgroundColor:'white'}}>        
                  {console.log('https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + this.state.schoolName.split('-')[0] + ' - ' + this.state.schoolName.split('-')[1]+ '/logo')}
                  <View style={{flex:1, backgroundColor: 'white',}}>
                      <View style={{ backgroundColor: 'black',}}>
                      <View style={{flexDirection:'row', justifyContent:'center', }}>                       
                          <img src={'https://s3.amazonaws.com/certificate-for-fanzquiz/public/schools/' + this.state.schoolName.split('-')[0] + ' - ' + this.state.schoolName.split('-')[1]+ '/logo'}  style={{width: 35, height: 35, marginTop: 10, marginRight:10, }} alt="HTML5" />
                          <Text style={{textAlign: 'center',   fontSize:24, color: 'white', paddingTop: 15, fontWeight:500, fontfamily:'Helvetica'}}>{this.state.schoolName}</Text>
                      </View>  
                      <View style={{flexDirection:'row', justifyContent:'center'}}>   
                          {this.sportImage(this.state.sport) }
                          <Text style={{textAlign: 'center',  color: 'white', fontSize:16,paddingTop: 5, fontWeight:500, fontfamily:'Helvetica' }}>{this.state.sport}</Text>
                    
                      </View> 
                      <View style={{flexDirection:'row', paddingTop:10}} >
                          <Text style={{color:'white', flex:1, textAlign:'center'}}>Overall {this.state.overall}</Text> 
                          <Text style={{color:'white', flex:1, textAlign:'center'}}>District {this.state.dstrict}</Text> 
                    </View>
                      
                          <View style={{flex:1, flexDirection:'row', marginTop:20}}>  
                              <TouchableOpacity style={{flex:1,alignItem:'center',}} onPress={() => {this.setState({pageNo: 0,}) }}>                             
                                  <Text style={{ color: this.state.pageNo ===0 ? 'white' : '#8D9193',  textAlign:'center',fontSize:13,fontWeight:500, fontfamily:'Helvetica' }} >OVERVIEW</Text>                              
                                  <View style={{height:this.state.pageNo ===0 ? 2 : 0, backgroundColor:'red', marginLeft:30, marginRight:30, marginTop:10}}></View>
                              
                              </TouchableOpacity>
                              <TouchableOpacity style={{flex:1,alignItem:'center'}} onPress={() => {this.setState({pageNo: 1,}) }}>
                                  <Text style={{color:this.state.pageNo ===1 ? 'white' : '#8D9193',textAlign:'center',fontSize:13,fontWeight:500, fontfamily:'Helvetica'}}>SCHEDULE</Text>      
                                  <View style={{height:this.state.pageNo ===1 ? 2 : 0, backgroundColor:'red', marginLeft:30, marginRight:30, marginTop:10}}></View>
                              </TouchableOpacity>
                              <TouchableOpacity style={{flex:1,alignItem:'center'}} onPress={() => {this.setState({pageNo: 2,}) }}>
                                  <Text style={{color:this.state.pageNo ===2 ? 'white' : '#8D9193', textAlign:'center', fontSize:13, fontWeight:500, fontfamily:'Helvetica'}}>STANDING</Text>
                                  <View style={{height:this.state.pageNo ===2 ? 2 : 0, backgroundColor:'red', marginLeft:30, marginRight:30, marginTop:10}}></View>
                              </TouchableOpacity>
                          </View>
                      </View>        

                      { 
                          this.state.pageNo ===1 ? 
                          <Fixtures fixtureData = {this.props.fixtureData} currentTeam = {this.props.currentTeamSel.split("-")[0]}/>
                          : this.state.pageNo===2 ?
                          
                          <Table standingProps={this.state.district + '-' + headerTitle.split('-')[1].split(" ")[0] + '-' + headerTitle.split('-')[1].split(" ")[1] } currentTeam = {this.props.currentTeamSel.split("-")[0]}/>
                          :
                          <TeamOverview  routeProps = {this.props.routeProps} currentTeam = {this.props.currentTeamSel.split("-")[0]} fixtureData={this.props.fixtureData} teamNewData = {this.props.teamNewData} rosterData={this.props.rosterData} props_element={Number(this.props.propsElement)}/>
                  
                          
                      }                   
              
                  </View>
                        
              </View>
          </View>  
          </Amplitude>
      </AmplitudeProvider> 
    );          
  }
  /*
  <Route  path={`${this.props.routeProps.match.path}/news/:id`}
                render={(props) => < NewsPage />}
            /> 
  */
    render() { 
    //console.log(this.props)
      
      return (
        <div>  
            <Route  path={`${this.props.routeProps.match.path}/news/:id`}
                render={(props) => < NewsPage {...this.props.teamNewData[this.props.newPageIndex]} routeProps={props}/>}
            /> 

            <Route  path={`${this.props.routeProps.match.path}/roster/:id`}
                render={(props) => <PlayerPage />}
            />     
            <Route
                exact
                path={this.props.routeProps.match.path}
                render={() => <h3>{this.MainPageFun()}</h3>}
            /> 
        </div>
            )
                  
      }
  
    
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#eaeaea',
    },
    scrollContainer: {
      //paddingTop: 16,
      backgroundColor:'#D0D3D5'
      //paddingTop: HEADER_EXPANDED_HEIGHT
    },
        indicator: {
        flex: 1,
        flexDirection: 'column-reverse',
        width: Dimensions.get('window').width,
        height: 300,
        
      },
      indicatorContainer: {
        backgroundColor: 'black',
        height: 60,
        
        },
      indicatorStyle: {
        width: 50,  
        },
      indicatorText: {
        fontSize: 13,
        color: '#8D9193',
        
        
      },
      indicatorSelectedText: {
        fontSize: 13,
        color: 'white',
        fontWeight:'500'
      },
      selectedBorderStyle: {
        
        height: 3,
        backgroundColor: '#e03a3e',
      },
  });


const mapStateToProps = state => {
    return { 
        login: state.login,
        currentTeamSel: state.currentTeamSel,       
        playerPageindex : state.playerPageindex,
        rosterData : state.rosterData,
        propsElement : state.propsElement,
        fixtureData : state.fixtureData,
        teamNewData: state.teamNewData,
        playerDetailInfo : state.playerDetailInfo,
        newPageIndex : state.newPageIndex,   
        
    }
}
export default connect(mapStateToProps, actions)(AppSettingPage);