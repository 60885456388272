import { connect } from 'react-redux';
import * as actions from './actions';
import * as React from 'react';
import {Platform,StyleSheet,Text,View, ScrollView, Dimensions, BackHandler, Image, ImageBackground,TouchableOpacity} from 'react-native';
import { HashRouter } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { FaCog} from 'react-icons/fa';
import amplitude from "amplitude-js";
import { Player, ControlBar } from 'video-react';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import "../node_modules/video-react/dist/video-react.css";
import {
    FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon,
  } from 'react-share';
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,LogOnChange
} from "@amplitude/react-amplitude";



//this.props.teamNewData[this.props.newPageIndex]

class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            Imageratio:1,
            PageSizeWidth:Dimensions.get('window').width ,
            pageURL:'',
        }
    }

    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
       window.scrollTo(0, 0)
       this.setState({pageURL: window.location.href})
       
    }
    
      componentWillUnmount() {
         
      }
      handleResize = () => {
        console.log(Dimensions.get('window'))
        if (Dimensions.get('window').width<=770){
        this.setState({PageSizeWidth:Dimensions.get('window').width, Imageratio:Dimensions.get('window').width/700 })
        }
        else {
        this.setState({PageSizeWidth:700,})
        }
    }

    render () {   
       console.log('this.props')
        if ('topic' in this.props) {        
            return (
                
                <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                 apiKey='265ecd4214dc923fea7ba20a854373ff'
               
                >
                    <Amplitude
                        eventProperties={{                        
                        "story": this.props.topic,
                        }}
                        >                 
                        <LogOnMount eventType="NewsPage" />
                       
                        <View style={{paddingBottom:20,  flex:1,backgroundColor:'#D0D3D5', paddingBottom:10, marginBottom:20,  }} >
                        <View style = {{padding:10, flex:1, flexDirection:'row', justifyContent:'space-evenly', backgroundColor:'white', width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',}}>
                                    <FacebookShareButton
                                    url={'https://allamericanhighschoolsports.com'}
                                    quote={this.props.topic}>
                                        <FacebookIcon
                                            size={40}
                                            round />
                                    </FacebookShareButton>

                                    <TwitterShareButton 
                                        url={this.state.pageURL}
                                        title={this.props.topic}>
                                        <TwitterIcon
                                            size={40}
                                            round />
                                    </TwitterShareButton>

                                    <WhatsappShareButton 
                                        url={this.state.pageURL}
                                        title={this.props.topic}>
                                        <WhatsappIcon
                                            size={40}
                                            round />
                                    </WhatsappShareButton>

                                    <EmailShareButton
                                        url={this.state.pageURL}
                                        subject={this.props.topic}>
                                        <EmailIcon
                                            size={40}
                                            round />
                                    </EmailShareButton>


                                </View>
                        {
                            this.props.fileType != 'video' ?
                            
                            
        
                            
                                
                            

          

                            <View style = {{backgroundColor: "white",width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',}}>            
                                
                                 <ScrollView  style={{marginBottom: 100, }}>
                                    <View style={{height: isMobile? 270 : 400*this.state.Imageratio,width:'100%',flexDirection:'row', }}>                     
                                    <Image
                                        //resizeMode="contain"
                                        style={{flex: 1,alignSelf: 'stretch', width:'100%', height: undefined}}
                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + this.props.topic.trim().replace("?","")}}>
                                    </Image>
                                    </View>
                                    <View style={{marginLeft: 8,marginRight:8 }}>
                                        <Text style={{color:'#444546', fontfamily: 'Helvetica', fontSize:23, fontWeight: '600', textAlign: 'center', letterSpacing: 0.6,marginTop:10}}>{this.props.topic}</Text>
                                        <Text style={{color:'#757878', fontSize:12, marginTop:10, letterSpacing: 0.8, fontWeight: '450',}}>{this.props.author}</Text>
                                        <Text style={{color:'#757878', fontSize:12, letterSpacing: 0.8,marginBottom:10, fontWeight: '450',}}>{this.props.readTime + ' read'}</Text>
                                        <Text style={{color:'#444546', fontFamily: 'Georgia', fontSize:17,  lineHeight: 26,letterSpacing: 0.5, marginBottom:30}}>{this.props.story}</Text>
                                    </View>
                                </ScrollView>     
                            </View>                              
                             :  
                              <View style={{width:isMobile? '100%' : this.state.PageSizeWidth, alignSelf:'center',}}>
                                <Player
                                        playsInline
                                        autoPlay
                                      
                                        src= {'https://s3.amazonaws.com/certificate-for-fanzquiz/public/headlines/' + this.props.story}
                                        fluid={false}
                                         width= {isMobile? '100%' : this.state.PageSizeWidth}
                                        height={isMobile? Dimensions.get('window').height-160 : Dimensions.get('window').height-50}
                                       style={{alignSelf:'center',}}
                                      
                                    />
                                </View>
                            }
                                
                            </View>
                        
                    </Amplitude>
                </AmplitudeProvider>
               
               
                  
            );   
        }
        
        else return null   
    } 

    
} 

const mapStateToProps = state => {
    return {          
        //newPageIndex : state.newPageIndex,       
        //teamNewData: state.teamNewData,            
    }
}
export default connect(mapStateToProps, actions)(NewsPage);